import React, { Component } from 'react';

class FullDate extends Component {

    constructor(props) {
        super(props);
        this.state = {

        };

        this.clickFullDate = this.clickFullDate.bind(this);
    }

    clickFullDate(){
        const parentElement = document.getElementById(''+this.props.idOnclick);

// Find elements with the class "targetClass" inside the parent element
        const targetElements = parentElement.querySelectorAll('.react-date-picker__calendar-button');

        targetElements.forEach(element => {
            element.dispatchEvent(new Event('click', { bubbles: true }));
        });
        // console.log(targetElements);
        // targetElements.forEach(element => {
        //     console.log(element); // Output the text content of each element
        // });
        // document.getElementById(''+this.props.idOnclick).className('react-date-picker__calendar-button__icon react-date-picker__button__icon').click();
        // document.getElementById(''+this.props.idOnclick).classList.remove('react-date-picker__calendar--closed');
        // document.getElementById(''+this.props.idOnclick).classList.add('react-date-picker__calendar--open');


    }
    render() {
        const monthsName = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
        ];
        let dt = this.props.date;
        let d = new Date(dt);
        let mo = d.getMonth();
        let dd = d.getDate();
        let fulldate = dd+' '+monthsName[mo]+' '+d.getFullYear();
        return (

            <span className="fulldate-calendar" onClick={this.clickFullDate}>{fulldate}</span>
        );
    }
}

export default FullDate;
