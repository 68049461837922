import React, { Component } from 'react';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { addDays } from 'date-fns';

class DateRangeCalendar extends Component {
    constructor(props) {
        super(props);
        this.state = {
        //     data: [],
        //     selection1: {
        //         startDate: addDays(new Date(), 1),
        //         endDate: addDays(new Date(), 1),
        //         key: 'selection1',
        //         autoFocus: false,
        //     },
        //     selection2: {
        //         startDate: addDays(new Date(), 4),
        //         endDate: addDays(new Date(), 8),
        //         key: 'selection2',
        //         autoFocus: false,
        //     },
        //     selection3: {
        //         startDate: addDays(new Date(), 8),
        //         endDate: addDays(new Date(), 10),
        //         key: 'selection3',
        //         autoFocus: false,
        //     },
        //     selection4: {
        //         startDate: addDays(new Date(), 11),
        //         endDate: addDays(new Date(), 12),
        //         key: 'selection4',
        //         autoFocus: false,
        //     },
        //     selection5: {
        //         startDate: addDays(new Date(), 13),
        //         endDate: addDays(new Date(), 17),
        //         key: 'selection5',
        //         autoFocus: false,
        //     },
        };

        // this.state = {
        //     dateRanges: [
        //         {
        //             startDate: new Date(),
        //             endDate: new Date(),
        //             key: 'selection',
        //         },
        //     ],
        // };
    }

    handleDateChange = (item) => {
        this.setState({ ...this.state, ...item });
    };


    handleSelect = (ranges) => {
        this.setState({
            dateRanges: [ranges.selection],
        });
    };

    componentDidMount() {
    //     const script = document.createElement('script');
    //     script.src = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5561643830892352';
    //     script.async = true;
    //     script.crossOrigin = 'anonymous';
    //     document.head.appendChild(script);
    //
    //     script.onload = () => {
    //         (window.adsbygoogle = window.adsbygoogle || []).push({});
    //     };
    }

    render() {
        const {
            dataCalendarShow = []
        } = this.props;

        // const { dateRanges } = this.state;
        const dateRangeStyles = {
            width: '100%', // Set initial width as 100% of the container
            minWidth: '290px', // Set a maximum width if needed
            margin: '0 auto', // Center the DateRange component
        };

        return (
            <div>
                    <DateRange
                        onChange={this.handleDateChange}
                        ranges={dataCalendarShow}
                        // rangeColors={['#001AFF', '#001AFF', '#FFD600', '#FFD600', '#F00']}
                        // rangeColors={['#001AFF','#FFD600','#F00','#001AFF','#FFD600','#F00','#001AFF','#FFD600','#F00','#001AFF','#FFD600','#F00','#001AFF','#FFD600','#F00','#001AFF','#FFD600','#F00','#001AFF','#FFD600','#F00']}
                        showSelectionPreview={false}
                        // showMonthAndYearPickers={false}
                        editableDateInputs={false}
                        showPreview={false}
                        showMonthArrow={false}
                        scroll={{ enabled: false }}
                        months={6}
                        showDateDisplay={false}
                        style={dateRangeStyles}

                    />

                {/*<DateRange*/}
                {/*    ranges={dateRanges}*/}
                {/*    onChange={this.handleSelect}*/}
                {/*    moveRangeOnFirstSelection={false}*/}
                {/*    months={2}*/}
                {/*    showSelectionPreview={true}*/}
                {/*    direction="horizontal"*/}
                {/*/>*/}
                {/*<div>*/}
                {/*    <h2>Selected Date Ranges:</h2>*/}
                {/*    <ul>*/}
                {/*        {dateRanges.map((range, index) => (*/}
                {/*            <li key={index}>*/}
                {/*                {`${range.startDate.toDateString()} - ${range.endDate.toDateString()}`}*/}
                {/*            </li>*/}
                {/*        ))}*/}
                {/*    </ul>*/}
                {/*</div>*/}
            </div>
        );
    }

}

export default DateRangeCalendar;
