import React from 'react';
import {
    Container, Input, Row, Label, FormGroup, Col
} from 'reactstrap';
import ReactHtmlParser from 'react-html-parser';
import ProfileImg from '../img/profile.png';

import {Editor} from '@tinymce/tinymce-react';
import {LazyLoadImage} from "react-lazy-load-image-component";
import IconImage from "../img/icon/icon-image.png";
import {TbCurrencyBaht} from "react-icons/tb";
import {FaMoneyBillWaveAlt} from "react-icons/fa";

// import 'tinymce/skins/ui/oxide-dark/skin.min.css';
class Post extends React.Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef();
        this.state = {
            // search: "",
            tripName: "",
            tripDescription: "",
            tripDestination: "",

            flightUrl: "",
            hotelUrl: "",
            carUrl: "",
            motorUrl: "",

            flightPrice: "",
            hotelPrice: "",
            carPrice: "",
            motorPrice: "",

            linkFlight: "",
            linkCar: "",
            linkTrain: "",
            linkHotel: "",
            linkMotor: "",
            linkFlightPrice: "",
            linkCarPrice: "",
            linkTrainPrice: "",
            linkHotelPrice: "",
            linkMotorPrice: "",

            contentTitle: "",
            // contentDescription: "",
            contentDescription: props.initialValue ?? '',
            contentDestination: "",
            // imageDay1: "",
            // imageDay2: "",

            userID: localStorage.getItem("userId"),
            userInfo: JSON.parse(localStorage.getItem('userInfo')),
            passportExpire: "",

            showModal: false,

            day: 0,
            dayList: [],

            fileList: [],
            files: [],

            fileListDays: [],
            filesDays: [],

            urls: [],
            urlsDay: [],
            titleNav: "Posting",
            // apiUrl: "http://localhost:3001",
            apiUrl: "https://fridayis-api.herokuapp.com",
            // apiUrlImage: "http://localhost:3002",
            apiUrlImage: "https://fridayis-image-api-8dd819ee4efe.herokuapp.com",
            // urlsDay: ["https://cdn-e8t8t7iu.files-simplefileupload.com/static/blobs/proxy/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBK29vQWc9PSIsImV4cCI6bnVsbCwicHVyIjoiYmxvYl9pZCJ9fQ==--c214dd95d93277b7a4bece63e3eebc2cc7acedc5/6bc6c3ed1988de7f6f9b84afe9e616eb.jpg","https://cdn-e8t8t7iu.files-simplefileupload.com/static/blobs/proxy/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBK29vQWc9PSIsImV4cCI6bnVsbCwicHVyIjoiYmxvYl9pZCJ9fQ==--c214dd95d93277b7a4bece63e3eebc2cc7acedc5/6bc6c3ed1988de7f6f9b84afe9e616eb.jpg","https://cdn-e8t8t7iu.files-simplefileupload.com/static/blobs/proxy/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBK29vQWc9PSIsImV4cCI6bnVsbCwicHVyIjoiYmxvYl9pZCJ9fQ==--c214dd95d93277b7a4bece63e3eebc2cc7acedc5/6bc6c3ed1988de7f6f9b84afe9e616eb.jpg","https://cdn-e8t8t7iu.files-simplefileupload.com/static/blobs/proxy/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBK29vQWc9PSIsImV4cCI6bnVsbCwicHVyIjoiYmxvYl9pZCJ9fQ==--c214dd95d93277b7a4bece63e3eebc2cc7acedc5/6bc6c3ed1988de7f6f9b84afe9e616eb.jpg","https://cdn-e8t8t7iu.files-simplefileupload.com/static/blobs/proxy/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBK29vQWc9PSIsImV4cCI6bnVsbCwicHVyIjoiYmxvYl9pZCJ9fQ==--c214dd95d93277b7a4bece63e3eebc2cc7acedc5/6bc6c3ed1988de7f6f9b84afe9e616eb.jpg","https://cdn-e8t8t7iu.files-simplefileupload.com/static/blobs/proxy/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBK29vQWc9PSIsImV4cCI6bnVsbCwicHVyIjoiYmxvYl9pZCJ9fQ==--c214dd95d93277b7a4bece63e3eebc2cc7acedc5/6bc6c3ed1988de7f6f9b84afe9e616eb.jpg","https://cdn-e8t8t7iu.files-simplefileupload.com/static/blobs/proxy/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBK29vQWc9PSIsImV4cCI6bnVsbCwicHVyIjoiYmxvYl9pZCJ9fQ==--c214dd95d93277b7a4bece63e3eebc2cc7acedc5/6bc6c3ed1988de7f6f9b84afe9e616eb.jpg"]

        };
        this.logout = this.logout.bind(this);
        this.toggle = this.toggle.bind(this);
        this.clickLinkFlight = this.clickLinkFlight.bind(this);
        this.clickLinkHotel = this.clickLinkHotel.bind(this);
        this.clickLinkTrain = this.clickLinkTrain.bind(this);
        this.clickLinkCar = this.clickLinkCar.bind(this);
        this.clickLinkMotor = this.clickLinkMotor.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.clickSlideOut = this.clickSlideOut.bind(this);
        this.handleEditorChange = this.handleEditorChange.bind(this);

        this.handleUploadClick = this.handleUploadClick.bind(this);
        this.handleUploadDaysClick = this.handleUploadDaysClick.bind(this);
        this.handleFileChange = this.handleFileChange.bind(this);
        this.handleFileDaysChange = this.handleFileDaysChange.bind(this);

        // this.clickFileInput = this.clickFileInput.bind(this);
        this.handleMultipleUpload = this.handleMultipleUpload.bind(this);
        this.handleMultipleUploadDay1 = this.handleMultipleUploadDay1.bind(this);
        this.ShowAddImageBox = this.ShowAddImageBox.bind(this);
        this.toggleProfile = this.toggleProfile.bind(this);
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.getUrlTimeline = this.getUrlTimeline.bind(this);
        // this.toggleVisible = this.toggleVisible.bind(this);
        // this.toggleMobile = this.toggleMobile.bind(this);
        // this.closeMobile = this.closeMobile.bind(this);
        //
        // this.handleMultipleUploadMobile = this.handleMultipleUploadMobile.bind(this);
        // this.handleMultipleUploadDay1Mobile = this.handleMultipleUploadDay1Mobile.bind(this);

        this.iconUploadClick = this.iconUploadClick.bind(this);

        this.addDays = this.addDays.bind(this);
        this.saveDataDayLast = this.saveDataDayLast.bind(this);
        this.addDaysLast = this.addDaysLast.bind(this);
        this.handleUploadDaysClickLast = this.handleUploadDaysClickLast.bind(this);
        this.addtrip = this.addtrip.bind(this);
        // this.handleMultipleUploadDay2 = this.handleMultipleUploadDay2.bind(this);
        // this.handleKeyDown = this.handleKeyDown.bind(this);

    }

    componentDidMount() {
        if (!localStorage.getItem("userId")) {
            window.location.href = '/';
        } else {
            // var element = document.getElementsByClassName("react-tabs__tab")[0];
            // if(element.classList.contains("react-tabs__tab--selected") === true){
            //     element.classList.remove("react-tabs__tab--selected");
            // }


            let fileStart = this.state.fileList ? [...this.state.fileList] : [];
            this.setState({files: fileStart});

            if(this.state.userInfo.passportExpire){
                const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
                const firstDate = new Date();
                const secondDate = new Date(this.state.userInfo.passportExpire);
                const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));
                this.setState({passportExpire: diffDays});
            }
            else{
                this.setState({passportExpire: ""});
            }
            // this.getAllData();
        }

        // window.addEventListener('scroll', this.toggleVisible);

        // if(this.state.tripDestination !== '' && this.state.tripName !== '' && this.state.tripDescription !== ''){
        if(this.state.tripName !== '' && this.state.tripDescription !== ''){
            if(this.state.contentDescription !== ''){
                document.getElementById("post-button").disabled = false;
            }
            else{
                document.getElementById("post-button").disabled = true;
            }

        }
        else{
            document.getElementById("post-button").disabled = true;
        }

    }

    componentDidUpdate(prevProps) {
        if (this.props.initialValue !== prevProps.initialValue) {
            this.setState({contentDescription: this.props.initialValue ?? ''})
        }
    }

    // handleKeyDown(e) {
    // e.target.style.height = 'inherit';
    // if(e.target.value){
    //     e.target.style.height = `${e.target.scrollHeight}px`;
    // }

    // e.target.style.height = 'inherit';
    // e.target.style.height = `${e.target.scrollHeight}px`;
    // In case you have a limitation
    // e.target.style.height = `${Math.min(e.target.scrollHeight, limit)}px`;
    // }

    handleChange(e) {
        switch (e.target.name) {
            case "search":
                this.setState({search: e.target.value});
                break;
            case "tripName":
                this.setState({tripName: e.target.value},()=>{
                    document.getElementById("checkTripName").style.display = "none";
                    // if(this.state.tripDestination !== '' && this.state.tripName !== '' && this.state.tripDescription !== ''){
                    if(this.state.tripName !== '' && this.state.tripDescription !== ''){
                        if(this.state.contentDescription !== ''){
                            // document.getElementById("post-button-mobile").disabled = false;
                            document.getElementById("post-button").disabled = false;
                        }
                        else{
                            // document.getElementById("post-button-mobile").disabled = true;
                            document.getElementById("post-button").disabled = true;
                        }

                    }
                    else{
                        // document.getElementById("post-button-mobile").disabled = true;
                        document.getElementById("post-button").disabled = true;
                    }
                });
                break;
            case "tripDescription":
                this.setState({tripDescription: e.target.value}, () => {
                    document.getElementById("checkTripDescription").style.display = "none";
                    if (e.target.value) {
                        e.target.style.height = 'inherit';
                        e.target.style.height = `${e.target.scrollHeight}px`;
                    } else {
                        e.target.style.height = 'inherit';
                        e.target.style.height = `60px`;
                    }

                    // if(this.state.tripDestination !== '' && this.state.tripName !== '' && this.state.tripDescription !== ''){
                    if(this.state.tripName !== '' && this.state.tripDescription !== ''){
                        if(this.state.contentDescription !== ''){
                            // document.getElementById("post-button-mobile").disabled = false;
                            document.getElementById("post-button").disabled = false;
                        }
                        else{
                            // document.getElementById("post-button-mobile").disabled = true;
                            document.getElementById("post-button").disabled = true;
                        }

                    }
                    else{
                        // document.getElementById("post-button-mobile").disabled = true;
                        document.getElementById("post-button").disabled = true;
                    }
                });
                break;
            // case "tripDestination":
            //     this.setState({tripDestination: e.target.value},()=>{
            //         document.getElementById("checkTripDestination").style.display = "none";
            //         if(this.state.tripDestination !== '' && this.state.tripName !== '' && this.state.tripDescription !== ''){
            //             if(this.state.contentDescription !== ''){
            //                 // document.getElementById("post-button-mobile").disabled = false;
            //                 document.getElementById("post-button").disabled = false;
            //             }
            //             else{
            //                 // document.getElementById("post-button-mobile").disabled = true;
            //                 document.getElementById("post-button").disabled = true;
            //             }
            //
            //         }
            //         else{
            //             // document.getElementById("post-button-mobile").disabled = true;
            //             document.getElementById("post-button").disabled = true;
            //         }
            //     });
            //     break;
            // case "Description":
            //     this.setState({Description : e.target.value});
            //     break;
            case "linkFlight":
                this.setState({linkFlight: e.target.value});
                break;
            case "linkCar":
                this.setState({linkCar: e.target.value});
                break;
            case "linkHotel":
                this.setState({linkHotel: e.target.value});
                break;
            case "linkTrain":
                this.setState({linkTrain: e.target.value});
                break;
            case "linkMotor":
                this.setState({linkMotor: e.target.value});
                break;
            case "linkFlightPrice":
                this.setState({linkFlightPrice: e.target.value});
                break;
            case "linkCarPrice":
                this.setState({linkCarPrice: e.target.value});
                break;
            case "linkHotelPrice":
                this.setState({linkHotelPrice: e.target.value});
                break;
            case "linkTrainPrice":
                this.setState({linkTrainPrice: e.target.value});
                break;
            case "linkMotorPrice":
                this.setState({linkMotorPrice: e.target.value});
                break;
            case "flightUrl":
                this.setState({flightUrl: e.target.value});
                break;
            case "hotelUrl":
                this.setState({hotelUrl: e.target.value});
                break;
            case "carUrl":
                this.setState({carUrl: e.target.value});
                break;
            case "motorUrl":
                this.setState({motorUrl: e.target.value});
                break;
            case "flightPrice":
                this.setState({flightPrice: e.target.value});
                break;
            case "hotelPrice":
                this.setState({hotelPrice: e.target.value});
                break;
            case "carPrice":
                this.setState({carPrice: e.target.value});
                break;
            case "motorPrice":
                this.setState({motorPrice: e.target.value});
                break;
            case "contentTitle":
                this.setState({contentTitle: e.target.value});
                break;
            case "contentDestination":
                this.setState({contentDestination: e.target.value});
                break;
            // case "contentDescription":
            //     this.setState({contentDescription : e.target.value});
            //     break;
            default:
        }
    }

    handleFileChange(e) {
        console.log(e.target.files);

        this.setState({fileList: e.target.files},()=>{
            let fileStart = this.state.fileList ? [...this.state.fileList] : [];
            this.setState({files: fileStart},()=>{
                // console.log(this.state.files);
                // console.log(this.state.fileList);

                // if (document.getElementById("imageCustomPreview").scrollLeft !== document.getElementById("imageCustomPreview").scrollWidth) {
                //     document.getElementById("imageCustomPreview").scrollTo(286*(this.state.files.length + 1), 0);
                // }

            });
        });

        // e.target.files.forEach((file) => {
        //     console.log('<LazyLoadImage effect="blur"  id="thumb" src={window.URL.createObjectURL(file)} fetchpriority="high" className="upload-img-preview-286" alt="Upload preview" />');
        // });

        // this.setState({preview: ''});
        // document.getElementById('thumb').src = URL.createObjectURL(e.target.files[0]);
    };

    handleFileDaysChange(e) {
        console.log(e.target.files);

        this.setState({fileListDays: e.target.files},()=>{
            let fileStart = this.state.fileListDays ? [...this.state.fileListDays] : [];
            this.setState({filesDays: fileStart},()=>{
            });
        });

    };

    handleUploadClick() {
        if (!this.state.fileList) {
            return;
        }

        // 👇 Create new FormData object and append files
        // const data = new FormData();
        // this.state.files.forEach((file, i) => {
        //     data.append(`file-${i}`, file, file.name);
        // });

        let formData = new FormData();
        this.state.files.forEach((file) => {
            formData.append("file", file);
        });

        console.log(this.state.files);

        // 👇 Uploading the files using the fetch API to the server
        // fetch('http://localhost:3002/api/upload', {
        fetch(this.state.apiUrlImage+'/upload-multiple', {
            // fetch('https://fridayis-image.herokuapp.com/post', {
            method: 'POST',
            body: formData,
        })
            .then((res) => res.json())
            .then((data) => {
                // if(data.urls){
                //     this.setState({urls: data.urls})
                // }
                // console.log(data.url);
                if(data){
                    this.setState({urls: data},()=>{
                        this.setState({files: []},()=>{
                            this.addtrip();
                        })
                    })
                }
                // console.log(data);
            })
            .catch((err) => console.error(err));
    };

    handleUploadDaysClick() {
        if (!this.state.fileListDays) {
            return;
        }

        let formData = new FormData();
        this.state.filesDays.forEach((file) => {
            formData.append("file", file);
        });

        // console.log(this.state.filesDays);

        // 👇 Uploading the files using the fetch API to the server
        // fetch('http://localhost:3002/api/upload', {
        fetch(this.state.apiUrlImage+'/upload-multiple', {
            // fetch('https://fridayis-image.herokuapp.com/post', {
            method: 'POST',
            body: formData,
        })
            .then((res) => res.json())
            .then((data) => {
                // if(data.urls){
                //     this.setState({urls: data.urls})
                // }
                // console.log(data.url);
                if(data){
                    this.setState({urlsDay: data},()=>{
                        this.setState({filesDays: []},()=>{
                            this.addDays();
                        })
                    })
                }
                // console.log(data);
            })
            .catch((err) => console.error(err));
    };


    // handleEditorChange(e){
    //     // console.log('Content was updated:', e.target.getContent());
    //     this.setState({contentDescription: e.target.getContent()});
    // }

    handleEditorChange(contentDescription, editor) {
        this.setState({contentDescription},()=>{
            document.getElementById("checkContentDescription").style.display = "none";
            // if(this.state.tripDestination !== '' && this.state.tripName !== '' && this.state.tripDescription !== ''){
            if(this.state.tripName !== '' && this.state.tripDescription !== ''){
                if(this.state.contentDescription !== ''){
                    // document.getElementById("post-button-mobile").disabled = false;
                    document.getElementById("post-button").disabled = false;
                }
                else{
                    // document.getElementById("post-button-mobile").disabled = true;
                    document.getElementById("post-button").disabled = true;
                }

            }
            else{
                // document.getElementById("post-button-mobile").disabled = true;
                document.getElementById("post-button").disabled = true;
            }
        });
    }

    clickLinkFlight(num) {
        let element = document.getElementById("flightInputBox" + num);
        let element2 = document.getElementById("flightIcon" + num);

        if (element.classList.contains("d-none") === false) {
            element.classList.add("d-none");
            element2.classList.remove("d-none");
        } else {
            element.classList.remove("d-none");
            element2.classList.add("d-none");
        }

    }

    clickLinkCar(num) {
        let elementCar = document.getElementById("carInputBox" + num);
        let element2 = document.getElementById("carIcon" + num);
        if (elementCar.classList.contains("d-none") === false) {
            elementCar.classList.add("d-none");
            element2.classList.remove("d-none");
        } else {
            elementCar.classList.remove("d-none");
            element2.classList.add("d-none");
        }
    }

    clickLinkTrain(num) {
        let elementTrain = document.getElementById("trainInputBox" + num);
        let element2 = document.getElementById("trainIcon" + num);
        if (elementTrain.classList.contains("d-none") === false) {
            elementTrain.classList.add("d-none");
            element2.classList.remove("d-none");
        } else {
            elementTrain.classList.remove("d-none");
            element2.classList.add("d-none");
        }

    }

    clickLinkMotor(num) {
        let elementMotor = document.getElementById("motorInputBox" + num);
        let element2 = document.getElementById("motorIcon" + num);
        if (elementMotor.classList.contains("d-none") === false) {
            elementMotor.classList.add("d-none");
            element2.classList.remove("d-none");
        } else {
            elementMotor.classList.remove("d-none");
            element2.classList.add("d-none");
        }

    }

    clickLinkHotel(num) {
        let elementHotel = document.getElementById("hotelInputBox" + num);
        let element2 = document.getElementById("hotelIcon" + num);
        if (elementHotel.classList.contains("d-none") === false) {
            elementHotel.classList.add("d-none");
            element2.classList.remove("d-none");
        } else {
            elementHotel.classList.remove("d-none");
            element2.classList.add("d-none");
        }

    }

    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    logout() {
        // this.setState({memberId: ""});
        localStorage.clear();
        sessionStorage.clear();
        window.location.href = "/";
    }

    clickSlideOut() {
        var element = document.getElementById("slideout_inner");
        if (element.classList.contains("show") === false) {
            element.classList.add("show");
        } else {
            element.classList.remove("show");
        }

    }

    saveDataDay() {

        let check = true;
        let focus = '';
        if(this.state.contentDescription === ''){
            document.getElementById("checkContentDescription").style.display = "block";
            check = false;
            if(!focus){
                focus = 'contentDescription';
            }
        }else{
            document.getElementById("checkContentDescription").style.display = "none";
        }


        if(check === true) {
            if(this.state.filesDays.length > 0) {
                let elInput = document.getElementById('image-days-button');
                elInput.click();
            }
            else{
                this.addDays();
            }

        }
        else{
            document.getElementById(focus).focus();
        }

    }

    addDays(){
        let newData = [...this.state.dayList];
        newData[this.state.day] = [];
        newData[this.state.day][0] = this.state.contentTitle;
        newData[this.state.day][1] = this.state.contentDescription;
        newData[this.state.day][2] = this.state.linkFlight;
        newData[this.state.day][3] = this.state.linkHotel;
        newData[this.state.day][4] = this.state.linkCar;
        newData[this.state.day][5] = this.state.linkTrain;
        newData[this.state.day][6] = this.state.linkMotor;
        newData[this.state.day][7] = this.state.urlsDay;
        newData[this.state.day][8] = this.state.contentDestination;
        newData[this.state.day][9] = this.state.linkFlightPrice;
        newData[this.state.day][10] = this.state.linkHotelPrice;
        newData[this.state.day][11] = this.state.linkCarPrice;
        newData[this.state.day][12] = this.state.linkTrainPrice;
        newData[this.state.day][13] = this.state.linkMotorPrice;
        // newData[this.state.day][8] = this.state.imageDay2;
        console.log(newData);
        this.setState({dayList: newData}, () => {
            let daySave = parseInt(this.state.day) + 1;
            this.setState({day: daySave}, () => {
                this.setState({contentDescription: ""});
                this.setState({contentDestination: ""});
                this.setState({urlsDay: []});
                this.setState({linkFlight: ""}, () => {
                    this.setState({linkFlightPrice: ""}, () => {
                        let element = document.getElementById("flightInputBox0");
                        let element6 = document.getElementById("flightIcon0");
                        if (element.classList.contains("d-none") === false) {
                            element.classList.add("d-none");
                            element6.classList.remove("d-none");
                        }
                    });
                });
                this.setState({linkHotel: ""}, () => {
                    this.setState({linkHotelPrice: ""}, () => {
                        let element2 = document.getElementById("hotelInputBox0");
                        let element7 = document.getElementById("hotelIcon0");
                        if (element2.classList.contains("d-none") === false) {
                            element2.classList.add("d-none");
                            element7.classList.remove("d-none");
                        }
                    });
                });
                this.setState({linkCar: ""}, () => {
                    this.setState({linkCartPrice: ""}, () => {
                        let element3 = document.getElementById("carInputBox0");
                        let element8 = document.getElementById("carIcon0");
                        if (element3.classList.contains("d-none") === false) {
                            element3.classList.add("d-none");
                            element8.classList.remove("d-none");
                        }
                    });
                });
                // this.setState({linkTrain: ""},()=>{
                //     let element4 = document.getElementById("trainInputBox0");
                //     if(element4.classList.contains("d-none") === false) {
                //         element4.classList.add("d-none");
                //     }
                // });
                this.setState({linkMotor: ""}, () => {
                    this.setState({linkMotorPrice: ""}, () => {
                        let element5 = document.getElementById("motorInputBox0");
                        let element9 = document.getElementById("motorIcon0");
                        if (element5.classList.contains("d-none") === false) {
                            element5.classList.add("d-none");
                            element9.classList.remove("d-none");
                        }
                    });
                });
                // this.setState({imageDay1: ""});
                // this.setState({imageDay2: ""});

                // let elementImage = document.getElementById("image-day");
                // if(elementImage.classList.contains("d-none") === false){
                //     elementImage.classList.add("d-none");
                // }


                // console.log(this.state.dayList);
                // console.log(this.state.day);
            });
        });
    }

    saveDataDayLast() {

        let check = true;
        let focus = '';
        if(this.state.contentDescription === ''){
            document.getElementById("checkContentDescription").style.display = "block";
            check = false;
            if(!focus){
                focus = 'contentDescription';
            }
        }else{
            document.getElementById("checkContentDescription").style.display = "none";
        }


        if(check === true) {
            if(this.state.filesDays.length > 0) {
                let elInput = document.getElementById('image-days-button-last');
                elInput.click();
            }
            else{
                this.addDaysLast();
            }

        }
        else{
            document.getElementById(focus).focus();
        }

    }
    handleUploadDaysClickLast() {
        if (!this.state.fileListDays) {
            return;
        }

        let formData = new FormData();
        this.state.filesDays.forEach((file) => {
            formData.append("file", file);
        });

        // console.log(this.state.filesDays);

        // 👇 Uploading the files using the fetch API to the server
        // fetch('http://localhost:3002/api/upload', {
        fetch(this.state.apiUrlImage+'/upload-multiple', {
            // fetch('https://fridayis-image.herokuapp.com/post', {
            method: 'POST',
            body: formData,
        })
            .then((res) => res.json())
            .then((data) => {
                // if(data.urls){
                //     this.setState({urls: data.urls})
                // }
                // console.log(data.url);
                if(data){
                    this.setState({urlsDay: data},()=>{
                        this.setState({filesDays: []},()=>{
                            this.addDaysLast();
                        })
                    })
                }
                // console.log(data);
            })
            .catch((err) => console.error(err));
    };
    addDaysLast(){
        let newData = [...this.state.dayList];
        newData[this.state.day] = [];
        newData[this.state.day][0] = this.state.contentTitle;
        newData[this.state.day][1] = this.state.contentDescription;
        newData[this.state.day][2] = this.state.linkFlight;
        newData[this.state.day][3] = this.state.linkHotel;
        newData[this.state.day][4] = this.state.linkCar;
        newData[this.state.day][5] = this.state.linkTrain;
        newData[this.state.day][6] = this.state.linkMotor;
        newData[this.state.day][7] = this.state.urlsDay;
        newData[this.state.day][8] = this.state.contentDestination;
        newData[this.state.day][9] = this.state.linkFlightPrice;
        newData[this.state.day][10] = this.state.linkHotelPrice;
        newData[this.state.day][11] = this.state.linkCarPrice;
        newData[this.state.day][12] = this.state.linkTrainPrice;
        newData[this.state.day][13] = this.state.linkMotorPrice;
        // newData[this.state.day][8] = this.state.imageDay2;
        console.log(newData);
        this.setState({dayList: newData}, () => {
            let daySave = parseInt(this.state.day) + 1;
            this.setState({day: daySave}, () => {
                this.setState({contentDescription: ""});
                this.setState({contentDestination: ""});
                this.setState({urlsDay: []});
                this.setState({linkFlight: ""}, () => {
                    this.setState({linkFlightPrice: ""}, () => {
                        let element = document.getElementById("flightInputBox0");
                        let element6 = document.getElementById("flightIcon0");
                        if (element.classList.contains("d-none") === false) {
                            element.classList.add("d-none");
                            element6.classList.remove("d-none");
                        }
                    });
                });
                this.setState({linkHotel: ""}, () => {
                    this.setState({linkHotelPrice: ""}, () => {
                        let element2 = document.getElementById("hotelInputBox0");
                        let element7 = document.getElementById("hotelIcon0");
                        if (element2.classList.contains("d-none") === false) {
                            element2.classList.add("d-none");
                            element7.classList.remove("d-none");
                        }
                    });
                });
                this.setState({linkCar: ""}, () => {
                    this.setState({linkCartPrice: ""}, () => {
                        let element3 = document.getElementById("carInputBox0");
                        let element8 = document.getElementById("carIcon0");
                        if (element3.classList.contains("d-none") === false) {
                            element3.classList.add("d-none");
                            element8.classList.remove("d-none");
                        }
                    });
                });
                this.setState({linkMotor: ""}, () => {
                    this.setState({linkMotorPrice: ""}, () => {
                        let element5 = document.getElementById("motorInputBox0");
                        let element9 = document.getElementById("motorIcon0");
                        if (element5.classList.contains("d-none") === false) {
                            element5.classList.add("d-none");
                            element9.classList.remove("d-none");
                        }
                    });
                });

                if(this.state.files.length > 0) {
                    let elInputImg = document.getElementById('image-button');
                    elInputImg.click();
                }
                else{
                    this.addtrip();
                }

            });
        });
    }

    addtrip(){
        fetch(this.state.apiUrl + '/api/trip',
            {
                body: JSON.stringify(this.state),
                method: 'POST',
                headers: {"Content-type": "application/json;charset=UTF-8"}
            })
            .then(res => res.json())
            .then((data) => {
                // window.location.href = '/home';
                // this.setState({ data: data });
                this.onclickClear();

            });
    }

    saveData(e) {
        e.preventDefault();
        // console.log('hiii');
        // if (this.state.fileList) {
        //     let formData = new FormData();
        //     this.state.files.forEach((file) => {
        //         formData.append("file", file);
        //     });

        // fetch('https://fridayis-image.herokuapp.com/post', {
        //     method: 'POST',
        //     body: formData,
        // })
        //     .then((res) => res.json())
        //     .then((data) => {
        //         if(data.urls){
        //             this.setState({urls: data.urls},()=>{

        let check = true;
        let focus = '';
        // if(this.state.tripDestination === ''){
        //     document.getElementById("checkTripDestination").style.display = "block";
        //     check = false;
        //     if(!focus){
        //         focus = 'tripDestination';
        //     }
        // }else{
        //     document.getElementById("checkTripDestination").style.display = "none";
        // }

        if(this.state.tripName === ''){
            document.getElementById("checkTripName").style.display = "block";
            check = false;
            if(!focus){
                focus = 'tripName';
            }
        }else{
            document.getElementById("checkTripName").style.display = "none";
        }

        if(this.state.tripDescription === ''){
            document.getElementById("checkTripDescription").style.display = "block";
            check = false;
            if(!focus){
                focus = 'tripDescription';
            }
        }else{
            document.getElementById("checkTripDescription").style.display = "none";

        }

        if(check === true) {
            if(this.state.contentDescription !== '') {
                this.saveDataDayLast();

                // fetch(this.state.apiUrl + '/api/trip',
                //     {
                //         body: JSON.stringify(this.state),
                //         method: 'POST',
                //         headers: {"Content-type": "application/json;charset=UTF-8"}
                //     })
                //     .then(res => res.json())
                //     .then((data) => {
                //         // window.location.href = '/home';
                //         // this.setState({ data: data });
                //         this.onclickClear();
                //
                //     });
            }
            // else{
            //     fetch(this.state.apiUrl + '/api/trip',
            //         {
            //             body: JSON.stringify(this.state),
            //             method: 'POST',
            //             headers: {"Content-type": "application/json;charset=UTF-8"}
            //         })
            //         .then(res => res.json())
            //         .then((data) => {
            //             // window.location.href = '/home';
            //             // this.setState({ data: data });
            //             this.onclickClear();
            //
            //         });
            // }

        }
        else{
            document.getElementById(focus).focus();
        }

        // if(this.state.contentDescription !== ''){
        //     this.saveDataDayLast();
        //     console.log('here');
        //
        //         // let newData = [...this.state.dayList];
        //         // newData[this.state.day] = [];
        //         // newData[this.state.day][0] = this.state.contentTitle;
        //         // newData[this.state.day][1] = this.state.contentDescription;
        //         // newData[this.state.day][2] = this.state.linkFlight;
        //         // newData[this.state.day][3] = this.state.linkHotel;
        //         // newData[this.state.day][4] = this.state.linkCar;
        //         // newData[this.state.day][5] = this.state.linkTrain;
        //         // newData[this.state.day][6] = this.state.linkMotor;
        //         // newData[this.state.day][7] = this.state.urlsDay;
        //         // newData[this.state.day][8] = this.state.contentDestination;
        //         // // newData[this.state.day][8] = this.state.imageDay2;
        //         // // console.log(newData);
        //         // this.setState({dayList: newData}, () => {
        //         //     let daySave = parseInt(this.state.day) + 1;
        //         //     this.setState({day: daySave}, () => {
        //         //         this.setState({contentDescription: ""});
        //         //         this.setState({contentDestination: ""});
        //         //         this.setState({urlsDay: []});
        //         //         this.setState({linkFlight: ""}, () => {
        //         //             let element = document.getElementById("flightInputBox0");
        //         //             let element6 = document.getElementById("flightIcon0");
        //         //             if (element.classList.contains("d-none") === false) {
        //         //                 element.classList.add("d-none");
        //         //                 element6.classList.remove("d-none");
        //         //             }
        //         //         });
        //         //         this.setState({linkHotel: ""}, () => {
        //         //             let element2 = document.getElementById("hotelInputBox0");
        //         //             let element7 = document.getElementById("hotelIcon0");
        //         //             if (element2.classList.contains("d-none") === false) {
        //         //                 element2.classList.add("d-none");
        //         //                 element7.classList.remove("d-none");
        //         //             }
        //         //         });
        //         //         this.setState({linkCar: ""}, () => {
        //         //             let element3 = document.getElementById("carInputBox0");
        //         //             let element8 = document.getElementById("carIcon0");
        //         //             if (element3.classList.contains("d-none") === false) {
        //         //                 element3.classList.add("d-none");
        //         //                 element8.classList.remove("d-none");
        //         //             }
        //         //         });
        //         //
        //         //         this.setState({linkMotor: ""}, () => {
        //         //             let element5 = document.getElementById("motorInputBox0");
        //         //             let element9 = document.getElementById("motorIcon0");
        //         //             if (element5.classList.contains("d-none") === false) {
        //         //                 element5.classList.add("d-none");
        //         //                 element9.classList.remove("d-none");
        //         //             }
        //         //
        //         //
        //         //             // console.log(this.state);
        //         //             if(check === true) {
        //         //                     fetch(this.state.apiUrl + '/api/trip',
        //         //                         {
        //         //                             body: JSON.stringify(this.state),
        //         //                             method: 'POST',
        //         //                             headers: {"Content-type": "application/json;charset=UTF-8"}
        //         //                         })
        //         //                         .then(res => res.json())
        //         //                         .then((data) => {
        //         //                             // window.location.href = '/home';
        //         //                             // this.setState({ data: data });
        //         //                             this.onclickClear();
        //         //
        //         //                         });
        //         //
        //         //                 // console.log(this.state);
        //         //             }
        //         //             else{
        //         //                 document.getElementById(focus).focus();
        //         //             }
        //         //         });
        //         //
        //         //     });
        //         // });
        //
        //
        // }
        // else{
        //     if(check === true) {
        //
        //                 fetch(this.state.apiUrl + '/api/trip',
        //                     {
        //                         body: JSON.stringify(this.state),
        //                         method: 'POST',
        //                         headers: {"Content-type": "application/json;charset=UTF-8"}
        //                     })
        //                     .then(res => res.json())
        //                     .then((data) => {
        //                         // window.location.href = '/home';
        //                         // this.setState({ data: data });
        //                         this.onclickClear();
        //
        //                     });
        //
        //         // console.log(this.state);
        //
        //     }
        //     else{
        //         document.getElementById(focus).focus();
        //     }
        //
        // }




    }

    handleMultipleUpload(files) {
        // console.log(files);

        let url = [];
        files.forEach((file) => {
            url.push(file.cdnUrl);

        });

        this.setState({urls: url});

        // let url = [...this.state.urls];
        // url.push(files);

        this.setState({urls: url}, () => {
            //console.log(this.state.urls);
            if (document.getElementById("imagePreview").scrollLeft !== document.getElementById("imagePreview").scrollWidth) {
                document.getElementById("imagePreview").scrollTo(286*(this.state.urls.length + 1), 0);
            }
        });
    }

    // handleMultipleUploadMobile(files) {
    //     // console.log(files);
    //
    //     // let url = [...this.state.urls];
    //     // files.forEach((file) => {
    //     //     url.push(file.cdnUrl);
    //     //
    //     // });
    //     //
    //     // this.setState({urls: url});
    //
    //     let url = [...this.state.urls];
    //     url.push(files);
    //
    //     this.setState({urls: url}, () => {
    //         //console.log(this.state.urls);
    //         if (document.getElementById("imagePreview").scrollLeft !== document.getElementById("imagePreview").scrollWidth) {
    //             document.getElementById("imagePreview").scrollTo(286*(this.state.urls.length + 1), 0);
    //         }
    //     });
    // }

    handleMultipleUploadDay1(files) {

        // let url = [...this.state.urls];
        // url.push(files);

        // this.setState({imageDay1: url}, () => {
        //     // console.log(this.state.urls);
        // });urlsDay
        // let url = [...this.state.urlsDay];
        // url.push(files);
        let url = [];
        files.forEach((file) => {
            url.push(file.cdnUrl);

        });

        this.setState({urlsDay: url}, () => {
            // console.log(this.state.urls);
            console.log(this.state.urlsDay);
            if (document.getElementById("imagePreviewDay").scrollLeft !== document.getElementById("imagePreviewDay").scrollWidth) {
                document.getElementById("imagePreviewDay").scrollTo(195*(this.state.urlsDay.length + 1), 0);
            }
        });
    }

    // handleMultipleUploadDay1Mobile(files) {
    //
    //     // let url = [...this.state.urls];
    //     // url.push(files);
    //
    //     // this.setState({imageDay1: url}, () => {
    //     //     // console.log(this.state.urls);
    //     // });urlsDay
    //     let url = [...this.state.urlsDay];
    //     url.push(files);
    //
    //     this.setState({urlsDay: url}, () => {
    //         //console.log(this.state.urls);
    //         if (document.getElementById("imagePreviewDay").scrollLeft !== document.getElementById("imagePreviewDay").scrollWidth) {
    //             document.getElementById("imagePreviewDay").scrollTo(195*(this.state.urlsDay.length + 1), 0);
    //         }
    //     });
    // }

    // handleMultipleUploadDay2(url) {
    //
    //     // let url = [...this.state.urls];
    //     // url.push(files);
    //
    //     this.setState({imageDay2: url}, () => {
    //         // console.log(this.state.urls);
    //     });
    // }

    handleOnDrop() {

    }

    ShowAddImageBox() {

        let elementImage = document.getElementById("image-day");
        if (elementImage.classList.contains("d-none") === false) {
            // elementMotor.classList.add("d-none");
        } else {
            elementImage.classList.remove("d-none");
        }
    }

    toggleProfile(id) {
        var element = document.getElementById("arrow-down-profile");
        var element2 = document.getElementById("arrow-up-profile");
        if(element2.classList.contains("d-none") === false){
            element2.classList.add("d-none");
        }
        else{
            element2.classList.remove("d-none");
        }
        if(element.classList.contains("d-none") === false){
            element.classList.add("d-none");
        }
        else{
            element.classList.remove("d-none");
        }


        let elementProfile = document.getElementById(id);
        if(elementProfile.classList.contains("d-none") === false){
            elementProfile.classList.add("d-none");
        }
        else{
            elementProfile.classList.remove("d-none");
        }

    }

    openModal(){
        this.setState({showModal: true});
    }

    closeModal() {
        this.setState({showModal: false});
    }

    static contextTypes = {
        router: () => true, // replace with PropTypes.object if you use them
    }

    getUrlTimeline(id){
        if(id === 2){
            sessionStorage.setItem("defaultIndex", 1);
            window.location.href = '/home?ctrip=all';
        }
        else{
            sessionStorage.setItem("defaultIndex", 0);
            window.location.href = '/home';
        }

    }

    // toggleVisible(){
    //     const scrolled = document.documentElement.scrollTop;
    //     if (scrolled > 300){
    //         this.setState({visible: true});
    //
    //     }
    //     else if (scrolled <= 300){
    //         this.setState({visible: false});
    //     }
    //
    //     if (scrolled > 50){
    //         document.getElementById("navMobile").classList.add('bg-transparent-white');
    //         document.getElementById("navMobile").classList.remove('bg-white');
    //     }
    //     else if (scrolled <= 50){
    //         document.getElementById("navMobile").classList.add('bg-white');
    //         document.getElementById("navMobile").classList.remove('bg-transparent-white');
    //     }
    // };

    // toggleMobile() {
    //     document.getElementById("sidenavMobile").style.width = "100%";
    // }
    //
    // closeMobile(){
    //     document.getElementById("sidenavMobile").style.width = "0";
    // }

    iconUploadClick(){
        document.getElementById("fileUploadInput").click();
    }

    onclickClear(){

        // e.preventDefault();
        var element =  window.parent.document.getElementById("iframe-content");
        var element2 =  window.parent.document.getElementsByClassName("ReactModalPortal")[0];

        if(element.classList.contains("d-none") === false){
            element.classList.add("d-none");
            element2.classList.add("d-none");

        }
        else{
            element.classList.remove("d-none");
            element2.classList.remove("d-none");
        }

        window.parent.location.reload();
    }
    render() {
        const editorRef = this.myRef;
        return (
            <div className="bg-transparent post-page">
                {/*Show*/}
                <Container>
                    <div className="card mt-5 card-border-60">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-3 text-center pt-2">
                                    {(localStorage.getItem("imageUrl")) ? <img src={localStorage.getItem("imageUrl")} className="profile-img-post" alt="Profile" /> : <img src={ProfileImg} className="profile-img-post" alt="Profile" />}
                                </div>
                                <div className="col-6">
                                    {/*<FormGroup>*/}
                                    {/*    <Input type="text" className="border-0 input-no-outline" name="tripDestination"*/}
                                    {/*           id="tripDestination" value={this.state.tripDestination}*/}
                                    {/*           onChange={this.handleChange} placeholder="Destination"/>*/}
                                    {/*    <Label for="checkTripDestination" style={{display:  "none",color:"red"}} id ="checkTripDestination">Please Enter Destination </Label>*/}
                                    {/*</FormGroup>*/}
                                    <FormGroup>
                                        <Input type="text" className="border-0 input-no-outline" name="tripName"
                                               id="tripName" value={this.state.tripName} onChange={this.handleChange}
                                               placeholder="Title" autocomplete="off"/>
                                        <Label for="checkTripName" style={{display:  "none",color:"red"}} id ="checkTripName">Please Enter Title </Label>
                                    </FormGroup>
                                    <FormGroup>
                                        <textarea className="form-control border-0 input-no-outline" id="tripDescription"
                                                  name="tripDescription" rows="2" onChange={this.handleChange}
                                                  placeholder="Caption"></textarea>
                                        <Label for="checkTripDescription" style={{display:  "none",color:"red"}} id ="checkTripDescription">Please Enter Caption </Label>
                                    </FormGroup>
                                </div>
                                <div className="col-3 pt-4">
                                    {/*<div className="overflow-auto d-flex" id="imagePreview">*/}
                                    <input id="image-input" type="file" onChange={this.handleFileChange} multiple className="d-none"/>
                                    <button id="image-button" onClick={this.handleUploadClick} className="d-none">Upload</button>

                                    <img src={IconImage} onClick={()=>document.getElementById('image-input').click()} className="post-img" alt="file upload"/>


                                    {/*<div className="overflow-auto d-flex" id="imagePreview">*/}
                                    {/*    {(this.state.urls.length > 0) && this.state.urls?.map((file) => (<LazyLoadImage effect="blur" fetchpriority="high"  src={file} className="upload-img-preview-286" alt="Upload preview"/>))}*/}

                                    {/*</div>*/}
                                </div>
                                <div className="col-10 offset-2 pb-2">
                                    <div className="overflow-auto d-flex" id="imagePreview">
                                        {this.state.files?.map((file, i) => (
                                            <LazyLoadImage effect="blur" fetchpriority="high"  src={window.URL.createObjectURL(file)} className="upload-img-preview-286" alt="Upload preview"/>
                                        ))}
                                    </div>
                                </div>
                                <div className="col-10 offset-2">
                                    <div className="row">
                                        <div id="flightIconflightUrl" className="col-2 mb-3">
                                            {/*<div className="input-group mb-3 ">*/}
                                            <div className="input-group-prepend">
                                                <img
                                                    src={window.location.protocol + "//" + window.location.host + "/icon/airplane.png"}
                                                    className="icon-input-before" name="linkFlightButton"
                                                    id="linkFlightButton"
                                                    onClick={this.clickLinkFlight.bind(this, 'flightUrl')} alt="Airplane"/>
                                            </div>
                                        </div>
                                        <div id="flightInputBoxflightUrl" className="col-12 mb-3 d-none">
                                            <div className="row">
                                                <div className="col-8">
                                                    <div className="input-group">
                                                        <div className="input-group-prepend">
                                                            <img
                                                                src={window.location.protocol + "//" + window.location.host + "/icon/airplane.png"}
                                                                className="icon-input-update" name="linkFlightButton"
                                                                id="linkFlightButton"
                                                                onClick={this.clickLinkFlight.bind(this, 'flightUrl')} alt="Airplane"/>
                                                        </div>
                                                        <Input type="text" name="flightUrl" id="flightUrl"
                                                               className="form-control input-link-update-input" value={this.state.flightUrl}
                                                               onChange={this.handleChange} aria-label="linkFlight"
                                                               placeholder="Destination"
                                                               aria-describedby="linkFlightButton"/>
                                                    </div>
                                                </div>
                                                <div className="col-4">
                                                    <div className="input-group">
                                                        <div className="input-group-prepend">
                                                            <FaMoneyBillWaveAlt className="icon-input-update-currency"/>
                                                        </div>
                                                        <Input type="number" name="flightPrice" id="flightPrice"
                                                               className="form-control input-link-update-input" value={this.state.flightPrice}
                                                               onChange={this.handleChange} aria-label="flightPrice"
                                                               placeholder="Flight pricee"
                                                               aria-describedby="linkFlightButton"/>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                        <div id="hotelIconhotelUrl" className="col-2 mb-3">
                                            <div className="input-group-prepend">
                                                <img
                                                    src={window.location.protocol + "//" + window.location.host + "/icon/double-bed.png"}
                                                    className="icon-input-before" name="linkHotelButton"
                                                    id="linkHotelButton" onClick={this.clickLinkHotel.bind(this, 'hotelUrl')}
                                                    alt="Hotel"/>
                                            </div>
                                        </div>
                                        <div id="hotelInputBoxhotelUrl" className="col-12 mb-3 d-none">
                                            <div className="row">
                                                <div className="col-8">
                                                    <div className="input-group">
                                                        <div className="input-group-prepend">
                                                            <img
                                                                src={window.location.protocol + "//" + window.location.host + "/icon/double-bed.png"}
                                                                className="icon-input-update" name="linkHotelButton"
                                                                id="linkHotelButton" onClick={this.clickLinkHotel.bind(this, 'hotelUrl')}
                                                                alt="Hotel"/>
                                                        </div>
                                                        <Input type="text" name="hotelUrl" id="hotelUrl"
                                                               className="form-control input-link-update-input" value={this.state.hotelUrl}
                                                               onChange={this.handleChange} aria-label="linkHotel"
                                                               placeholder="Hotel name"
                                                               aria-describedby="linkHotelButton"/>
                                                    </div>
                                                </div>
                                                <div className="col-4">
                                                    <div className="input-group">
                                                        <div className="input-group-prepend">
                                                            <FaMoneyBillWaveAlt className="icon-input-update-currency"/>
                                                        </div>
                                                        <Input type="number" name="hotelPrice" id="hotelPrice"
                                                               className="form-control input-link-update-input" value={this.state.hotelPrice}
                                                               onChange={this.handleChange} aria-label="hotelPrice"
                                                               placeholder="Hotel price"
                                                               aria-describedby="linkHotelPriceButton"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div id="carIconcarUrl" className="col-2 mb-3">
                                            <div className="input-group-prepend">
                                                <img
                                                    src={window.location.protocol + "//" + window.location.host + "/icon/sedan.png"}
                                                    className="icon-input-before" name="linkCarButton" id="linkCarButton"
                                                    onClick={this.clickLinkCar.bind(this, 'carUrl')} alt="Car"/>
                                            </div>
                                        </div>
                                        <div id="carInputBoxcarUrl" className="col-12 mb-3 d-none">
                                            <div className="row">
                                                <div className="col-8">
                                                    <div className="input-group">
                                                        <div className="input-group-prepend">
                                                            <img
                                                                src={window.location.protocol + "//" + window.location.host + "/icon/sedan.png"}
                                                                className="icon-input-update" name="linkCarButton" id="linkCarButton"
                                                                onClick={this.clickLinkCar.bind(this, 'carUrl')} alt="Car"/>
                                                        </div>
                                                        <Input type="text" name="carUrl" id="carUrl" className="form-control input-link-update-input"
                                                               value={this.state.carUrl} onChange={this.handleChange}
                                                               placeholder="Pick up location"
                                                               aria-label="linkCar" aria-describedby="linkCarButton"/>
                                                    </div>
                                                </div>
                                                <div className="col-4">
                                                    <div className="input-group">
                                                        <div className="input-group-prepend">
                                                            <FaMoneyBillWaveAlt className="icon-input-update-currency"/>
                                                        </div>
                                                        <Input type="number" name="carPrice" id="carPrice" className="form-control input-link-update-input"
                                                               value={this.state.carPrice} onChange={this.handleChange}
                                                               placeholder="Rent price"
                                                               aria-label="carPrice" aria-describedby="linkCarPriceButton"/>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>

                                        <div id="motorIconmotorUrl" className="col-2 mb-3">
                                            <div className="input-group-prepend">
                                                <img
                                                    src={window.location.protocol + "//" + window.location.host + "/icon/motorbike.png"}
                                                    className="icon-input-before" name="linkMotorButton"
                                                    id="linkMotorButton" onClick={this.clickLinkMotor.bind(this, 'motorUrl')}
                                                    alt="Motor"/>
                                            </div>
                                        </div>
                                        <div id="motorInputBoxmotorUrl" className="col-12 mb-3 d-none">
                                            <div className="row">
                                                <div className="col-8">
                                                    <div className="input-group">
                                                        <div className="input-group-prepend">
                                                            <img
                                                                src={window.location.protocol + "//" + window.location.host + "/icon/motorbike.png"}
                                                                className="icon-input-update" name="linkMotorButton"
                                                                id="linkMotorButton" onClick={this.clickLinkMotor.bind(this, 'motorUrl')}
                                                                alt="Motor"/>
                                                        </div>
                                                        <Input type="text" name="motorUrl" id="motorUrl"
                                                               className="form-control input-link-update-input" value={this.state.motorUrl}
                                                               onChange={this.handleChange} aria-label="linkMotor"
                                                               placeholder="Pick up location"
                                                               aria-describedby="linkMotorButton"/>
                                                    </div>
                                                </div>
                                                <div className="col-4">
                                                    <div className="input-group">
                                                        <div className="input-group-prepend">
                                                            <FaMoneyBillWaveAlt className="icon-input-update-currency"/>
                                                        </div>
                                                        <Input type="number" name="motorPrice" id="motorPrice"
                                                               className="form-control input-link-update-input" value={this.state.motorPrice}
                                                               onChange={this.handleChange} aria-label="motorPrice"
                                                               placeholder="Rent price"
                                                               aria-describedby="linkMotorPriceButton"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div>
                            <Container className="py-5">
                                <div className="add-day-bar">
                                    {/*<button type="button" className="btn btn-light add-activity-button"*/}
                                    {/*        onClick={() => this.saveDataDay()}><span className="fw-bold">+Activity</span></button>*/}
                                    <button type="button" className="btn btn-light add-day-button mx-2"
                                            onClick={() => this.saveDataDay()}><span className="fw-bold">+Day</span></button>
                                    <button type="button" className="btn btn-light add-day-post-button" id="post-button"
                                            onClick={(e) => this.saveData(e)}><span className="fw-bold">Post</span>
                                    </button>
                                    {/*<a onClick={(e)=>this.onclick(e)} href="#">Click Me</a>*/}
                                </div>

                                {this.state.dayList.map((row, rowIndex) => (
                                    <div className="row">
                                        <div className="line-col col-2">
                                            <div className="line-timeline"> </div>
                                        </div>
                                        <div className="col-10">
                                            <Row className="day-timeline my-5">
                                                <Col xs="12" sm="12">
                                                    <FormGroup>
                                                        <p className="fw-bold">Day {rowIndex + 1}</p>
                                                    </FormGroup>
                                                    {(row[8]) && <p>{row[8]}</p>}
                                                    {(row[7].length > 0) &&
                                                        <div className="overflow-auto d-flex mb-3">
                                                            {row[7]?.map((fileImageDay, indexImageDay) => (
                                                                <img src={fileImageDay} className="show-img-preview-195" alt={'Day ' + (rowIndex + 1) + ' image '+(indexImageDay + 1)}/>
                                                            ))}
                                                        </div>
                                                    }

                                                    <div>
                                                        {ReactHtmlParser(row[1])}
                                                    </div>
                                                    <div className="row mb-3">
                                                        {row[2] && (
                                                            <div id={"flightIcon" + (rowIndex + 1)} className="col-2 mb-3 d-none">
                                                                <div className="input-group-prepend">
                                                                    <img
                                                                        src={window.location.protocol + "//" + window.location.host + "/icon/airplane.png"}
                                                                        className="icon-input-before" name="linkFlightButton"
                                                                        id="linkFlightButton"
                                                                        onClick={this.clickLinkFlight.bind(this, (rowIndex + 1))}
                                                                        alt="Airplane"/>
                                                                </div>
                                                            </div>
                                                        )}
                                                        {row[2] && (
                                                            <div id={"flightInputBox" + (rowIndex + 1)} className="col-12 mb-3">
                                                                <div className="row">
                                                                    <div className="col-8">
                                                                        <div className="input-group">
                                                                            <div className="input-group-prepend">
                                                                                <img
                                                                                    src={window.location.protocol + "//" + window.location.host + "/icon/airplane.png"}
                                                                                    className="icon-input-update" name="linkMotorButton"
                                                                                    id="linkMotorButton" onClick={this.clickLinkFlight.bind(this, (rowIndex + 1))}
                                                                                    alt="Motor"/>
                                                                            </div>
                                                                            <Input type="text" name="linkFlight" id="linkFlight"
                                                                                   className="form-control input-link-update" value={row[2]}
                                                                                   aria-label="linkFlight"
                                                                                   aria-describedby="linkFlightButton"/>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-4">
                                                                        <div className="input-group">
                                                                            <div className="input-group-prepend">
                                                                                <FaMoneyBillWaveAlt className="icon-input-update-currency"/>
                                                                            </div>
                                                                            <Input type="text" name="linkFlightPrice" id="linkFlightPrice"
                                                                                   className="form-control input-link-update" value={(row[9]) ? row[9] : 'Not specified'}
                                                                                   aria-label="linkFlightPrice"
                                                                                   aria-describedby="linkFlightPriceButton"/>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                        {row[3] && (
                                                            <div id={"hotelIcon" + (rowIndex + 1)} className="col-2 mb-3 d-none">
                                                                <div className="input-group-prepend">
                                                                    <img
                                                                        src={window.location.protocol + "//" + window.location.host + "/icon/double-bed.png"}
                                                                        className="icon-input-before" name="linkHotelButton"
                                                                        id="linkHotelButton"
                                                                        onClick={this.clickLinkHotel.bind(this, (rowIndex + 1))}
                                                                        alt="Hotel"/>
                                                                </div>
                                                            </div>
                                                        )}
                                                        {row[3] && (
                                                            <div id={"hotelInputBox" + (rowIndex + 1)}
                                                                 className="col-12 mb-3">
                                                                <div className="row">
                                                                    <div className="col-8"><div className="input-group">
                                                                        <div className="input-group-prepend">
                                                                            <img
                                                                                src={window.location.protocol + "//" + window.location.host + "/icon/double-bed.png"}
                                                                                className="icon-input-update" name="linkMotorButton"
                                                                                id="linkMotorButton" onClick={this.clickLinkHotel.bind(this, (rowIndex + 1))}
                                                                                alt="Motor"/>
                                                                        </div>
                                                                        <Input type="text" name="linkHotel" id="linkHotel"
                                                                               className="form-control input-link-update" value={row[3]}
                                                                               aria-label="linkHotel"
                                                                               aria-describedby="linkHotelButton"/>
                                                                    </div></div>
                                                                    <div className="col-4">
                                                                        <div className="input-group">
                                                                            <div className="input-group-prepend">
                                                                                <FaMoneyBillWaveAlt className="icon-input-update-currency"/>
                                                                            </div>
                                                                            <Input type="text" name="linkHotelPrice" id="linkHotelPrice"
                                                                                   className="form-control input-link-update" value={(row[10]) ? row[10] : 'Not specified'}
                                                                                   aria-label="linkHotelPrice"
                                                                                   aria-describedby="linkHotelPriceButton"/>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                        {row[4] && (
                                                            <div id={"carIcon" + (rowIndex + 1)} className="col-2 mb-3 d-none">
                                                                <div className="input-group-prepend">
                                                                    <img
                                                                        src={window.location.protocol + "//" + window.location.host + "/icon/sedan.png"}
                                                                        className="icon-input-before" name="linkCarButton"
                                                                        id="linkCarButton"
                                                                        onClick={this.clickLinkCar.bind(this, (rowIndex + 1))}
                                                                        alt="Car"/>
                                                                </div>
                                                            </div>
                                                        )}
                                                        {row[4] && (
                                                            <div id={"carInputBox" + (rowIndex + 1)}
                                                                 className="col-12 mb-3">
                                                                <div className="row">
                                                                    <div className="col-8">
                                                                        <div className="input-group">
                                                                            <div className="input-group-prepend">
                                                                                <img
                                                                                    src={window.location.protocol + "//" + window.location.host + "/icon/sedan.png"}
                                                                                    className="icon-input-update" name="linkMotorButton"
                                                                                    id="linkMotorButton" onClick={this.clickLinkCar.bind(this, (rowIndex + 1))}
                                                                                    alt="Motor"/>
                                                                            </div>
                                                                            <Input type="text" name="linkCar" id="linkCar"
                                                                                   className="form-control input-link-update" value={row[4]}
                                                                                   aria-label="linkCar" aria-describedby="linkCarButton"/>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-4">
                                                                        <div className="input-group">
                                                                            <div className="input-group-prepend">
                                                                                <FaMoneyBillWaveAlt className="icon-input-update-currency"/>
                                                                            </div>
                                                                            <Input type="text" name="linkCarPrice" id="linkCarPrice"
                                                                                   className="form-control input-link-update" value={(row[11]) ? row[11] : 'Not specified'}
                                                                                   aria-label="linkCarPrice"
                                                                                   aria-describedby="linkCarPriceButton"/>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                        {row[6] && (
                                                            <div id={"motorIcon" + (rowIndex + 1)} className="col-2 mb-3 d-none">
                                                                <div className="input-group-prepend">
                                                                    <img
                                                                        src={window.location.protocol + "//" + window.location.host + "/icon/motorbike.png"}
                                                                        className="icon-input-before" name="linkMotorButton"
                                                                        id="linkMotorButton"
                                                                        onClick={this.clickLinkMotor.bind(this, (rowIndex + 1))}
                                                                        alt="Motor"/>
                                                                </div>
                                                            </div>
                                                        )}
                                                        {row[6] && (
                                                            <div id={"motorInputBox" + (rowIndex + 1)}
                                                                 className="col-12 mb-3">
                                                                <div className="row">
                                                                    <div className="col-8">
                                                                        <div className="input-group">
                                                                            <div className="input-group-prepend">
                                                                                <img
                                                                                    src={window.location.protocol + "//" + window.location.host + "/icon/motorbike.png"}
                                                                                    className="icon-input-update" name="linkMotorButton"
                                                                                    id="linkMotorButton" onClick={this.clickLinkMotor.bind(this, (rowIndex + 1))}
                                                                                    alt="Motor"/>
                                                                            </div>
                                                                            <Input type="text" name="linkMotor" id="linkMotor"
                                                                                   className="form-control input-link-update" value={row[6]}
                                                                                   aria-label="linkMotor"
                                                                                   aria-describedby="linkMotorButton"/>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-4">
                                                                        <div className="input-group">
                                                                            <div className="input-group-prepend">
                                                                                <FaMoneyBillWaveAlt className="icon-input-update-currency"/>
                                                                            </div>
                                                                            <Input type="text" name="linkMotorPrice" id="linkMotorPrice"
                                                                                   className="form-control input-link-update" value={(row[13]) ? row[13] : 'Not specified'}
                                                                                   aria-label="linkMotorPrice"
                                                                                   aria-describedby="linkMotorPriceButton"/>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        )}
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                ))}

                                {/* Post */}
                                <div className="row mb-5">
                                    <div className="line-col col-2">
                                        <div className="line-timeline-last-child"> </div>
                                    </div>
                                    <div className="col-10">
                                        <Row className="day-timeline my-5">
                                            <Col xs="12" sm="12">
                                                <FormGroup>
                                                    <p className="fw-bold">Day {parseInt(this.state.day) + 1}</p>
                                                </FormGroup>

                                                <div className="card mt-5 card-border-60">
                                                    <div className="card-body mx-3">
                                                        <div className="row">
                                                            <div className="col-9">
                                                                {/*<FormGroup>*/}
                                                                {/*    <Input type="text" className="border-0 input-no-outline" name="tripDestination"*/}
                                                                {/*           id="tripDestination" value={this.state.tripDestination}*/}
                                                                {/*           onChange={this.handleChange} placeholder="Destination"/>*/}
                                                                {/*    <Label for="checkTripDestination" style={{display:  "none",color:"red"}} id ="checkTripDestination">Please Enter Destination </Label>*/}
                                                                {/*</FormGroup>*/}
                                                                <FormGroup>
                                                                    <Input type="text" className="border-0 input-no-outline" name="contentDestination"
                                                                           id="contentDestination" value={this.state.contentDestination}
                                                                           onChange={this.handleChange} placeholder="Add Location"/>
                                                                    {/*<Label for="checkTripDestination" style={{display:  "none",color:"red"}} id ="checkTripDestination">Please Enter Destination </Label>*/}
                                                                </FormGroup>
                                                                <FormGroup className="mt-2 p-0">
                                                                    <Editor
                                                                        id="contentDescription"
                                                                        name="contentDescription"
                                                                        onEditorChange={this.handleEditorChange}
                                                                        initialValue={this.props.initialValue}
                                                                        value={this.state.contentDescription}
                                                                        onInit={(evt, editor) => editorRef.current = editor}
                                                                        // initialValue=""
                                                                        init={{
                                                                            // skin: "oxide-dark",
                                                                            placeholder: "Write it here",
                                                                            // height: 100,
                                                                            menubar: false,
                                                                            plugins: [
                                                                                'autoresize',
                                                                                'advlist autolink lists link image charmap print preview anchor',
                                                                                'searchreplace visualblocks code fullscreen',
                                                                                'insertdatetime media table paste code help wordcount'
                                                                            ],
                                                                            toolbar: false,
                                                                            branding: false,
                                                                            statusbar: false,
                                                                            // toolbar: 'undo redo | formatselect | ' +
                                                                            //     'bold italic backcolor | alignleft aligncenter ' +
                                                                            //     'alignright alignjustify | bullist numlist outdent indent | ' +
                                                                            //     'removeformat | help',
                                                                            // content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                                                        }}
                                                                    />
                                                                    <Label for="checkContentDescription" style={{display:  "none",color:"red"}} id ="checkContentDescription">Please Enter Day {parseInt(this.state.day) + 1} content </Label>
                                                                </FormGroup>
                                                            </div>
                                                            <div className="col-3 pt-4">
                                                                <input id="image-days-input" type="file" onChange={this.handleFileDaysChange} multiple className="d-none"/>
                                                                <button id="image-days-button" onClick={this.handleUploadDaysClick} className="d-none">Upload</button>
                                                                <button id="image-days-button-last" onClick={this.handleUploadDaysClickLast} className="d-none">Upload</button>

                                                                <img src={IconImage} onClick={()=>document.getElementById('image-days-input').click()} className="post-img" alt="file upload"/>

                                                                {/*<div className="overflow-auto d-flex" id="imagePreviewDay">*/}
                                                                {/*    {(this.state.urlsDay.length > 0) && this.state.urlsDay?.map((file) => (<LazyLoadImage effect="blur" fetchpriority="high"  src={file} className="upload-img-preview-195" alt="Upload preview"/>))}*/}
                                                                {/*    <img src={IconImage} className="post-img" alt="file upload"/>*/}
                                                                {/*    */}
                                                                {/*</div>*/}
                                                            </div>
                                                            <div className="col-12 pb-2">
                                                                <div className="overflow-auto d-flex" id="imagePreviewDay">
                                                                    {this.state.filesDays?.map((file, i) => (
                                                                        <LazyLoadImage effect="blur" fetchpriority="high"  src={window.URL.createObjectURL(file)} className="upload-img-preview-286" alt="Upload preview"/>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                            <div className="col-12">
                                                                <div className="row">
                                                                    <div id="flightIcon0" className="col-2 mb-3">
                                                                        {/*<div className="input-group mb-3 ">*/}
                                                                        <div className="input-group-prepend">
                                                                            <img
                                                                                src={window.location.protocol + "//" + window.location.host + "/icon/airplane.png"}
                                                                                className="icon-input-before" name="linkFlightButton"
                                                                                id="linkFlightButton"
                                                                                onClick={this.clickLinkFlight.bind(this, 0)} alt="Airplane"/>
                                                                        </div>
                                                                    </div>
                                                                    <div id="flightInputBox0" className="col-12 mb-3 d-none">
                                                                        <div className="row">
                                                                            <div className="col-8">
                                                                                <div className="input-group">
                                                                                    <div className="input-group-prepend">
                                                                                        <img
                                                                                            src={window.location.protocol + "//" + window.location.host + "/icon/airplane.png"}
                                                                                            className="icon-input-update" name="linkFlightButton"
                                                                                            id="linkFlightButton"
                                                                                            onClick={this.clickLinkFlight.bind(this, 0)} alt="Airplane"/>
                                                                                    </div>
                                                                                    <Input type="text" name="linkFlight" id="linkFlight"
                                                                                           className="form-control input-link-update-input" value={this.state.linkFlight}
                                                                                           onChange={this.handleChange} aria-label="linkFlight"
                                                                                           placeholder="Destination"
                                                                                           aria-describedby="linkFlightButton"/>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-4">
                                                                                <div className="input-group">
                                                                                    <div className="input-group-prepend">
                                                                                        <FaMoneyBillWaveAlt className="icon-input-update-currency"/>
                                                                                    </div>
                                                                                    <Input type="number" name="linkFlightPrice" id="linkFlightPrice"
                                                                                           className="form-control input-link-update-input" value={this.state.linkFlightPrice}
                                                                                           onChange={this.handleChange} aria-label="linkFlightPrice"
                                                                                           placeholder="Flight price"
                                                                                           aria-describedby="linkFlightPriceButton"/>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div id="hotelIcon0" className="col-2 mb-3">
                                                                        <div className="input-group-prepend">
                                                                            <img
                                                                                src={window.location.protocol + "//" + window.location.host + "/icon/double-bed.png"}
                                                                                className="icon-input-before" name="linkHotelButton"
                                                                                id="linkHotelButton" onClick={this.clickLinkHotel.bind(this, 0)}
                                                                                alt="Hotel"/>
                                                                        </div>
                                                                    </div>
                                                                    <div id="hotelInputBox0" className="col-12 mb-3 d-none">
                                                                        <div className="row">
                                                                            <div className="col-8">
                                                                                <div className="input-group">
                                                                                    <div className="input-group-prepend">
                                                                                        <img
                                                                                            src={window.location.protocol + "//" + window.location.host + "/icon/double-bed.png"}
                                                                                            className="icon-input-update" name="linkHotelButton"
                                                                                            id="linkHotelButton" onClick={this.clickLinkHotel.bind(this, 0)}
                                                                                            alt="Hotel"/>
                                                                                    </div>
                                                                                    <Input type="text" name="linkHotel" id="linkHotel"
                                                                                           className="form-control input-link-update-input" value={this.state.linkHotel}
                                                                                           onChange={this.handleChange} aria-label="linkHotel"
                                                                                           placeholder="Hotel name"
                                                                                           aria-describedby="linkHotelButton"/>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-4">
                                                                                <div className="input-group">
                                                                                    <div className="input-group-prepend">
                                                                                        <FaMoneyBillWaveAlt className="icon-input-update-currency"/>
                                                                                    </div>
                                                                                    <Input type="number" name="linkHotelPrice" id="linkHotelPrice"
                                                                                           className="form-control input-link-update-input" value={this.state.linkHotelPrice}
                                                                                           onChange={this.handleChange} aria-label="linkHotelPrice"
                                                                                           placeholder="Hotel price"
                                                                                           aria-describedby="linkHotelPriceButton"/>
                                                                                </div>
                                                                            </div>
                                                                        </div>


                                                                    </div>

                                                                    <div id="carIcon0" className="col-2 mb-3">
                                                                        <div className="input-group-prepend">
                                                                            <img
                                                                                src={window.location.protocol + "//" + window.location.host + "/icon/sedan.png"}
                                                                                className="icon-input-before" name="linkCarButton" id="linkCarButton"
                                                                                onClick={this.clickLinkCar.bind(this, 0)} alt="Car"/>
                                                                        </div>
                                                                    </div>
                                                                    <div id="carInputBox0" className="col-12 mb-3 d-none">
                                                                            <div className="row">
                                                                            <div className="col-8">
                                                                                <div className="input-group">
                                                                                    <div className="input-group-prepend">
                                                                                        <img
                                                                                            src={window.location.protocol + "//" + window.location.host + "/icon/sedan.png"}
                                                                                            className="icon-input-update" name="linkCarButton" id="linkCarButton"
                                                                                            onClick={this.clickLinkCar.bind(this, 0)} alt="Car"/>
                                                                                    </div>
                                                                                    <Input type="text" name="linkCar" id="linkCar" className="form-control input-link-update-input"
                                                                                           value={this.state.linkCar} onChange={this.handleChange}
                                                                                           placeholder="Pick up location"
                                                                                           aria-label="linkCar" aria-describedby="linkCarButton"/>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-4">
                                                                                <div className="input-group">
                                                                                    <div className="input-group-prepend">
                                                                                        <FaMoneyBillWaveAlt className="icon-input-update-currency"/>
                                                                                    </div>
                                                                                    <Input type="number" name="linkCarPrice" id="linkCarPrice" className="form-control input-link-update-input"
                                                                                           value={this.state.linkCarPrice} onChange={this.handleChange}
                                                                                           placeholder="Rent price"
                                                                                           aria-label="linkCarPrice" aria-describedby="linkCarPriceButton"/>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>

                                                                    <div id="motorIcon0" className="col-2 mb-3">
                                                                        <div className="input-group-prepend">
                                                                            <img
                                                                                src={window.location.protocol + "//" + window.location.host + "/icon/motorbike.png"}
                                                                                className="icon-input-before" name="linkMotorButton"
                                                                                id="linkMotorButton" onClick={this.clickLinkMotor.bind(this, 0)}
                                                                                alt="Motor"/>
                                                                        </div>
                                                                    </div>
                                                                    <div id="motorInputBox0" className="col-12 mb-3 d-none">
                                                                        <div className="row">
                                                                            <div className="col-8">
                                                                                <div className="input-group">
                                                                                    <div className="input-group-prepend">
                                                                                        <img
                                                                                            src={window.location.protocol + "//" + window.location.host + "/icon/motorbike.png"}
                                                                                            className="icon-input-update" name="linkMotorButton"
                                                                                            id="linkMotorButton" onClick={this.clickLinkMotor.bind(this, 0)}
                                                                                            alt="Motor"/>
                                                                                    </div>
                                                                                    <Input type="text" name="linkMotor" id="linkMotor"
                                                                                           className="form-control input-link-update-input" value={this.state.linkMotor}
                                                                                           onChange={this.handleChange} aria-label="linkMotor"
                                                                                           placeholder="Pick up location"
                                                                                           aria-describedby="linkMotorButton"/>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-4">
                                                                                <div className="input-group">
                                                                                    <div className="input-group-prepend">
                                                                                        <FaMoneyBillWaveAlt className="icon-input-update-currency"/>
                                                                                    </div>
                                                                                    <Input type="number" name="linkMotorPrice" id="linkMotorPrice"
                                                                                           className="form-control input-link-update-input" value={this.state.linkMotorPrice}
                                                                                           onChange={this.handleChange} aria-label="linkMotorPrice"
                                                                                           placeholder="Rent price"
                                                                                           aria-describedby="linkMotorPriceButton"/>
                                                                                </div>
                                                                            </div>
                                                                        </div>


                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </Col>

                                        </Row>
                                    </div>
                                </div>

                            </Container>
                        </div>
                    </div>

                </Container>

            </div>
        );
    }
}

export default Post