import React from 'react';
import {
    Container, Navbar, NavbarBrand, Input, Row, Col
    , Collapse, Nav, NavItem, NavLink, FormGroup, Label, Card
} from 'reactstrap';
import ReactHtmlParser from 'react-html-parser';
import SimpleFileUpload from 'react-simple-file-upload';

import {FaPlus} from 'react-icons/fa';
import {GrFormClose, GrHomeRounded} from 'react-icons/gr';
import {HiOutlineTicket, HiOutlineCamera} from 'react-icons/hi';
import ProfileImg from '../img/profile.png';

import {Editor} from '@tinymce/tinymce-react';
import {LazyLoadImage} from "react-lazy-load-image-component";
import {IoIosArrowDown, IoIosArrowUp, IoIosClose} from "react-icons/io";
import {IoChevronBackCircleOutline} from "react-icons/io5";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import ComingSoon from "../img/Comingsoon.png";
import EarningMockup from "../img/earning-mockup.png";
import Modal from "react-modal";
import Logo from "../img/logo_new.png";
import FridayisLogoMobile from "../img/logo_new.png";
import IconSearch from "../img/icon/icon-search.png";
import ArrowSideout from "../img/icon/arrow-sideout.png";
import IconImage from "../img/icon/icon-image.png";

// import 'tinymce/skins/ui/oxide-dark/skin.min.css';
class Post extends React.Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef();
        this.state = {
            // search: "",
            tripName: "",
            tripDescription: "",
            tripDestination: "",

            flightUrl: "",
            hotelUrl: "",
            carUrl: "",
            motorUrl: "",

            linkFlight: "",
            linkCar: "",
            linkTrain: "",
            linkHotel: "",
            linkMotor: "",
            contentTitle: "",
            // contentDescription: "",
            contentDescription: props.initialValue ?? '',
            // imageDay1: "",
            // imageDay2: "",

            userID: localStorage.getItem("userId"),
            userInfo: JSON.parse(localStorage.getItem('userInfo')),
            passportExpire: "",

            showModal: false,

            day: 0,
            dayList: [],

            fileList: [],
            files: [],

            urls: [],
            urlsDay: [],
            titleNav: "Posting",
            // apiUrl: "http://localhost:3001",
            apiUrl: "https://fridayis-api.herokuapp.com",
            // urlsDay: ["https://cdn-e8t8t7iu.files-simplefileupload.com/static/blobs/proxy/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBK29vQWc9PSIsImV4cCI6bnVsbCwicHVyIjoiYmxvYl9pZCJ9fQ==--c214dd95d93277b7a4bece63e3eebc2cc7acedc5/6bc6c3ed1988de7f6f9b84afe9e616eb.jpg","https://cdn-e8t8t7iu.files-simplefileupload.com/static/blobs/proxy/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBK29vQWc9PSIsImV4cCI6bnVsbCwicHVyIjoiYmxvYl9pZCJ9fQ==--c214dd95d93277b7a4bece63e3eebc2cc7acedc5/6bc6c3ed1988de7f6f9b84afe9e616eb.jpg","https://cdn-e8t8t7iu.files-simplefileupload.com/static/blobs/proxy/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBK29vQWc9PSIsImV4cCI6bnVsbCwicHVyIjoiYmxvYl9pZCJ9fQ==--c214dd95d93277b7a4bece63e3eebc2cc7acedc5/6bc6c3ed1988de7f6f9b84afe9e616eb.jpg","https://cdn-e8t8t7iu.files-simplefileupload.com/static/blobs/proxy/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBK29vQWc9PSIsImV4cCI6bnVsbCwicHVyIjoiYmxvYl9pZCJ9fQ==--c214dd95d93277b7a4bece63e3eebc2cc7acedc5/6bc6c3ed1988de7f6f9b84afe9e616eb.jpg","https://cdn-e8t8t7iu.files-simplefileupload.com/static/blobs/proxy/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBK29vQWc9PSIsImV4cCI6bnVsbCwicHVyIjoiYmxvYl9pZCJ9fQ==--c214dd95d93277b7a4bece63e3eebc2cc7acedc5/6bc6c3ed1988de7f6f9b84afe9e616eb.jpg","https://cdn-e8t8t7iu.files-simplefileupload.com/static/blobs/proxy/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBK29vQWc9PSIsImV4cCI6bnVsbCwicHVyIjoiYmxvYl9pZCJ9fQ==--c214dd95d93277b7a4bece63e3eebc2cc7acedc5/6bc6c3ed1988de7f6f9b84afe9e616eb.jpg","https://cdn-e8t8t7iu.files-simplefileupload.com/static/blobs/proxy/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBK29vQWc9PSIsImV4cCI6bnVsbCwicHVyIjoiYmxvYl9pZCJ9fQ==--c214dd95d93277b7a4bece63e3eebc2cc7acedc5/6bc6c3ed1988de7f6f9b84afe9e616eb.jpg"]

        };
        this.logout = this.logout.bind(this);
        this.toggle = this.toggle.bind(this);
        this.clickLinkFlight = this.clickLinkFlight.bind(this);
        this.clickLinkHotel = this.clickLinkHotel.bind(this);
        this.clickLinkTrain = this.clickLinkTrain.bind(this);
        this.clickLinkCar = this.clickLinkCar.bind(this);
        this.clickLinkMotor = this.clickLinkMotor.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.clickSlideOut = this.clickSlideOut.bind(this);
        this.handleEditorChange = this.handleEditorChange.bind(this);

        this.handleUploadClick = this.handleUploadClick.bind(this);
        this.handleFileChange = this.handleFileChange.bind(this);

        // this.clickFileInput = this.clickFileInput.bind(this);
        this.handleMultipleUpload = this.handleMultipleUpload.bind(this);
        this.handleMultipleUploadDay1 = this.handleMultipleUploadDay1.bind(this);
        this.ShowAddImageBox = this.ShowAddImageBox.bind(this);
        this.toggleProfile = this.toggleProfile.bind(this);
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.getUrlTimeline = this.getUrlTimeline.bind(this);
        this.toggleVisible = this.toggleVisible.bind(this);
        this.toggleMobile = this.toggleMobile.bind(this);
        this.closeMobile = this.closeMobile.bind(this);

        this.handleMultipleUploadMobile = this.handleMultipleUploadMobile.bind(this);
        this.handleMultipleUploadDay1Mobile = this.handleMultipleUploadDay1Mobile.bind(this);

        this.iconUploadClick = this.iconUploadClick.bind(this);
        // this.handleMultipleUploadDay2 = this.handleMultipleUploadDay2.bind(this);
        // this.handleKeyDown = this.handleKeyDown.bind(this);

    }

    componentDidMount() {
        if (!localStorage.getItem("userId")) {
            window.location.href = '/';
        } else {
            var element = document.getElementsByClassName("react-tabs__tab")[0];
            if(element.classList.contains("react-tabs__tab--selected") === true){
                element.classList.remove("react-tabs__tab--selected");
            }


            let fileStart = this.state.fileList ? [...this.state.fileList] : [];
            this.setState({files: fileStart});

            if(this.state.userInfo.passportExpire){
                const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
                const firstDate = new Date();
                const secondDate = new Date(this.state.userInfo.passportExpire);
                const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));
                this.setState({passportExpire: diffDays});
            }
            else{
                this.setState({passportExpire: ""});
            }
            // this.getAllData();
        }

        window.addEventListener('scroll', this.toggleVisible);

        if(this.state.tripDestination !== '' && this.state.tripName !== '' && this.state.tripDescription !== ''){
            if(this.state.contentDescription !== ''){
                document.getElementById("post-button").disabled = false;
            }
            else{
                document.getElementById("post-button").disabled = true;
            }

        }
        else{
            document.getElementById("post-button").disabled = true;
        }

    }

    componentDidUpdate(prevProps) {
        if (this.props.initialValue !== prevProps.initialValue) {
            this.setState({contentDescription: this.props.initialValue ?? ''})
        }
    }

    // handleKeyDown(e) {
    // e.target.style.height = 'inherit';
    // if(e.target.value){
    //     e.target.style.height = `${e.target.scrollHeight}px`;
    // }

    // e.target.style.height = 'inherit';
    // e.target.style.height = `${e.target.scrollHeight}px`;
    // In case you have a limitation
    // e.target.style.height = `${Math.min(e.target.scrollHeight, limit)}px`;
    // }

    handleChange(e) {
        switch (e.target.name) {
            case "search":
                this.setState({search: e.target.value});
                break;
            case "tripName":
                this.setState({tripName: e.target.value},()=>{
                    document.getElementById("checkTripName").style.display = "none";
                    if(this.state.tripDestination !== '' && this.state.tripName !== '' && this.state.tripDescription !== ''){
                        if(this.state.contentDescription !== ''){
                            // document.getElementById("post-button-mobile").disabled = false;
                            document.getElementById("post-button").disabled = false;
                        }
                        else{
                            // document.getElementById("post-button-mobile").disabled = true;
                            document.getElementById("post-button").disabled = true;
                        }

                    }
                    else{
                        // document.getElementById("post-button-mobile").disabled = true;
                        document.getElementById("post-button").disabled = true;
                    }
                });
                break;
            case "tripDescription":
                this.setState({tripDescription: e.target.value}, () => {
                    document.getElementById("checkTripDescription").style.display = "none";
                    if (e.target.value) {
                        e.target.style.height = 'inherit';
                        e.target.style.height = `${e.target.scrollHeight}px`;
                    } else {
                        e.target.style.height = 'inherit';
                        e.target.style.height = `60px`;
                    }

                    if(this.state.tripDestination !== '' && this.state.tripName !== '' && this.state.tripDescription !== ''){
                        if(this.state.contentDescription !== ''){
                            // document.getElementById("post-button-mobile").disabled = false;
                            document.getElementById("post-button").disabled = false;
                        }
                        else{
                            // document.getElementById("post-button-mobile").disabled = true;
                            document.getElementById("post-button").disabled = true;
                        }

                    }
                    else{
                        // document.getElementById("post-button-mobile").disabled = true;
                        document.getElementById("post-button").disabled = true;
                    }
                });
                break;
            case "tripDestination":
                this.setState({tripDestination: e.target.value},()=>{
                    document.getElementById("checkTripDestination").style.display = "none";
                    if(this.state.tripDestination !== '' && this.state.tripName !== '' && this.state.tripDescription !== ''){
                        if(this.state.contentDescription !== ''){
                            // document.getElementById("post-button-mobile").disabled = false;
                            document.getElementById("post-button").disabled = false;
                        }
                        else{
                            // document.getElementById("post-button-mobile").disabled = true;
                            document.getElementById("post-button").disabled = true;
                        }

                    }
                    else{
                        // document.getElementById("post-button-mobile").disabled = true;
                        document.getElementById("post-button").disabled = true;
                    }
                });
                break;
            // case "Description":
            //     this.setState({Description : e.target.value});
            //     break;
            case "linkFlight":
                this.setState({linkFlight: e.target.value});
                break;
            case "linkCar":
                this.setState({linkCar: e.target.value});
                break;
            case "linkHotel":
                this.setState({linkHotel: e.target.value});
                break;
            case "linkTrain":
                this.setState({linkTrain: e.target.value});
                break;
            case "linkMotor":
                this.setState({linkMotor: e.target.value});
                break;
            case "flightUrl":
                this.setState({flightUrl: e.target.value});
                break;
            case "hotelUrl":
                this.setState({hotelUrl: e.target.value});
                break;
            case "carUrl":
                this.setState({carUrl: e.target.value});
                break;
            case "motorUrl":
                this.setState({motorUrl: e.target.value});
                break;
            case "contentTitle":
                this.setState({contentTitle: e.target.value});
                break;
            // case "contentDescription":
            //     this.setState({contentDescription : e.target.value});
            //     break;
            default:
        }
    }

    handleFileChange(e) {
        console.log(e.target.files);

        this.setState({fileList: e.target.files},()=>{
            let fileStart = this.state.fileList ? [...this.state.fileList] : [];
            this.setState({files: fileStart},()=>{
                // console.log(this.state.files);
                // console.log(this.state.fileList);
                if (document.getElementById("imageCustomPreview").scrollLeft !== document.getElementById("imageCustomPreview").scrollWidth) {
                    document.getElementById("imageCustomPreview").scrollTo(286*(this.state.files.length + 1), 0);
                }

            });
        });

        // e.target.files.forEach((file) => {
        //     console.log('<LazyLoadImage effect="blur"  id="thumb" src={window.URL.createObjectURL(file)} fetchpriority="high" className="upload-img-preview-286" alt="Upload preview" />');
        // });

        // this.setState({preview: ''});
        // document.getElementById('thumb').src = URL.createObjectURL(e.target.files[0]);
    };

    handleUploadClick() {
        if (!this.state.fileList) {
            return;
        }

        // 👇 Create new FormData object and append files
        // const data = new FormData();
        // this.state.files.forEach((file, i) => {
        //     data.append(`file-${i}`, file, file.name);
        // });

        let formData = new FormData();
        this.state.files.forEach((file) => {
            formData.append("file", file);
        });

        console.log(this.state.files);

        // 👇 Uploading the files using the fetch API to the server
        // fetch('http://localhost:3002/post', {
        // fetch('https://fridayis-image.herokuapp.com/post', {
        //     method: 'POST',
        //     body: formData,
        // })
        //     .then((res) => res.json())
        //     .then((data) => {
        //         if(data.urls){
        //             this.setState({urls: data.urls})
        //         }
        //         console.log(data)
        //     })
        //     .catch((err) => console.error(err));
    };


    // handleEditorChange(e){
    //     // console.log('Content was updated:', e.target.getContent());
    //     this.setState({contentDescription: e.target.getContent()});
    // }

    handleEditorChange(contentDescription, editor) {
        this.setState({contentDescription},()=>{
            document.getElementById("checkContentDescription").style.display = "none";
            if(this.state.tripDestination !== '' && this.state.tripName !== '' && this.state.tripDescription !== ''){
                if(this.state.contentDescription !== ''){
                    // document.getElementById("post-button-mobile").disabled = false;
                    document.getElementById("post-button").disabled = false;
                }
                else{
                    // document.getElementById("post-button-mobile").disabled = true;
                    document.getElementById("post-button").disabled = true;
                }

            }
            else{
                // document.getElementById("post-button-mobile").disabled = true;
                document.getElementById("post-button").disabled = true;
            }
        });
    }

    clickLinkFlight(num) {
        let element = document.getElementById("flightInputBox" + num);
        let element2 = document.getElementById("flightIcon" + num);

        if (element.classList.contains("d-none") === false) {
            element.classList.add("d-none");
            element2.classList.remove("d-none");
        } else {
            element.classList.remove("d-none");
            element2.classList.add("d-none");
        }

    }

    clickLinkCar(num) {
        let elementCar = document.getElementById("carInputBox" + num);
        let element2 = document.getElementById("carIcon" + num);
        if (elementCar.classList.contains("d-none") === false) {
            elementCar.classList.add("d-none");
            element2.classList.remove("d-none");
        } else {
            elementCar.classList.remove("d-none");
            element2.classList.add("d-none");
        }
    }

    clickLinkTrain(num) {
        let elementTrain = document.getElementById("trainInputBox" + num);
        let element2 = document.getElementById("trainIcon" + num);
        if (elementTrain.classList.contains("d-none") === false) {
            elementTrain.classList.add("d-none");
            element2.classList.remove("d-none");
        } else {
            elementTrain.classList.remove("d-none");
            element2.classList.add("d-none");
        }

    }

    clickLinkMotor(num) {
        let elementMotor = document.getElementById("motorInputBox" + num);
        let element2 = document.getElementById("motorIcon" + num);
        if (elementMotor.classList.contains("d-none") === false) {
            elementMotor.classList.add("d-none");
            element2.classList.remove("d-none");
        } else {
            elementMotor.classList.remove("d-none");
            element2.classList.add("d-none");
        }

    }

    clickLinkHotel(num) {
        let elementHotel = document.getElementById("hotelInputBox" + num);
        let element2 = document.getElementById("hotelIcon" + num);
        if (elementHotel.classList.contains("d-none") === false) {
            elementHotel.classList.add("d-none");
            element2.classList.remove("d-none");
        } else {
            elementHotel.classList.remove("d-none");
            element2.classList.add("d-none");
        }

    }

    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    logout() {
        // this.setState({memberId: ""});
        localStorage.clear();
        sessionStorage.clear();
        window.location.href = "/";
    }

    clickSlideOut() {
        var element = document.getElementById("slideout_inner");
        if (element.classList.contains("show") === false) {
            element.classList.add("show");
        } else {
            element.classList.remove("show");
        }

    }

    saveDataDay() {

        let check = true;
        let focus = '';
        if(this.state.contentDescription === ''){
            document.getElementById("checkContentDescription").style.display = "block";
            check = false;
            if(!focus){
                focus = 'contentDescription';
            }
        }else{
            document.getElementById("checkContentDescription").style.display = "none";
        }

        if(check === true) {
            let newData = [...this.state.dayList];
            newData[this.state.day] = [];
            newData[this.state.day][0] = this.state.contentTitle;
            newData[this.state.day][1] = this.state.contentDescription;
            newData[this.state.day][2] = this.state.linkFlight;
            newData[this.state.day][3] = this.state.linkHotel;
            newData[this.state.day][4] = this.state.linkCar;
            newData[this.state.day][5] = this.state.linkTrain;
            newData[this.state.day][6] = this.state.linkMotor;
            newData[this.state.day][7] = this.state.urlsDay;
            // newData[this.state.day][8] = this.state.imageDay2;
            console.log(newData);
            this.setState({dayList: newData}, () => {
                let daySave = parseInt(this.state.day) + 1;
                this.setState({day: daySave}, () => {
                    this.setState({contentDescription: ""});
                    this.setState({urlsDay: []});
                    this.setState({linkFlight: ""}, () => {
                        let element = document.getElementById("flightInputBox0");
                        let element6 = document.getElementById("flightIcon0");
                        if (element.classList.contains("d-none") === false) {
                            element.classList.add("d-none");
                            element6.classList.remove("d-none");
                        }
                    });
                    this.setState({linkHotel: ""}, () => {
                        let element2 = document.getElementById("hotelInputBox0");
                        let element7 = document.getElementById("hotelIcon0");
                        if (element2.classList.contains("d-none") === false) {
                            element2.classList.add("d-none");
                            element7.classList.remove("d-none");
                        }
                    });
                    this.setState({linkCar: ""}, () => {
                        let element3 = document.getElementById("carInputBox0");
                        let element8 = document.getElementById("carIcon0");
                        if (element3.classList.contains("d-none") === false) {
                            element3.classList.add("d-none");
                            element8.classList.remove("d-none");
                        }
                    });
                    // this.setState({linkTrain: ""},()=>{
                    //     let element4 = document.getElementById("trainInputBox0");
                    //     if(element4.classList.contains("d-none") === false) {
                    //         element4.classList.add("d-none");
                    //     }
                    // });
                    this.setState({linkMotor: ""}, () => {
                        let element5 = document.getElementById("motorInputBox0");
                        let element9 = document.getElementById("motorIcon0");
                        if (element5.classList.contains("d-none") === false) {
                            element5.classList.add("d-none");
                            element9.classList.remove("d-none");
                        }
                    });
                    // this.setState({imageDay1: ""});
                    // this.setState({imageDay2: ""});

                    // let elementImage = document.getElementById("image-day");
                    // if(elementImage.classList.contains("d-none") === false){
                    //     elementImage.classList.add("d-none");
                    // }


                    // console.log(this.state.dayList);
                    // console.log(this.state.day);
                });
            });
        }
        else{
            document.getElementById(focus).focus();
        }




    }

    saveData(e) {
        e.preventDefault();
        // console.log('hiii');
        // if (this.state.fileList) {
        //     let formData = new FormData();
        //     this.state.files.forEach((file) => {
        //         formData.append("file", file);
        //     });

        // fetch('https://fridayis-image.herokuapp.com/post', {
        //     method: 'POST',
        //     body: formData,
        // })
        //     .then((res) => res.json())
        //     .then((data) => {
        //         if(data.urls){
        //             this.setState({urls: data.urls},()=>{

        let check = true;
        let focus = '';
        if(this.state.tripDestination === ''){
            document.getElementById("checkTripDestination").style.display = "block";
            check = false;
            if(!focus){
                focus = 'tripDestination';
            }
        }else{
            document.getElementById("checkTripDestination").style.display = "none";
        }

        if(this.state.tripName === ''){
            document.getElementById("checkTripName").style.display = "block";
            check = false;
            if(!focus){
                focus = 'tripName';
            }
        }else{
            document.getElementById("checkTripName").style.display = "none";
        }

        if(this.state.tripDescription === ''){
            document.getElementById("checkTripDescription").style.display = "block";
            check = false;
            if(!focus){
                focus = 'tripDescription';
            }
        }else{
            document.getElementById("checkTripDescription").style.display = "none";

        }


        if(this.state.contentDescription !== ''){
            let newData = [...this.state.dayList];
            newData[this.state.day] = [];
            newData[this.state.day][0] = this.state.contentTitle;
            newData[this.state.day][1] = this.state.contentDescription;
            newData[this.state.day][2] = this.state.linkFlight;
            newData[this.state.day][3] = this.state.linkHotel;
            newData[this.state.day][4] = this.state.linkCar;
            newData[this.state.day][5] = this.state.linkTrain;
            newData[this.state.day][6] = this.state.linkMotor;
            newData[this.state.day][7] = this.state.urlsDay;
            // newData[this.state.day][8] = this.state.imageDay2;
            // console.log(newData);
            this.setState({dayList: newData}, () => {
                let daySave = parseInt(this.state.day) + 1;
                this.setState({day: daySave}, () => {
                    this.setState({contentDescription: ""});
                    this.setState({urlsDay: []});
                    this.setState({linkFlight: ""}, () => {
                        let element = document.getElementById("flightInputBox0");
                        let element6 = document.getElementById("flightIcon0");
                        if (element.classList.contains("d-none") === false) {
                            element.classList.add("d-none");
                            element6.classList.remove("d-none");
                        }
                    });
                    this.setState({linkHotel: ""}, () => {
                        let element2 = document.getElementById("hotelInputBox0");
                        let element7 = document.getElementById("hotelIcon0");
                        if (element2.classList.contains("d-none") === false) {
                            element2.classList.add("d-none");
                            element7.classList.remove("d-none");
                        }
                    });
                    this.setState({linkCar: ""}, () => {
                        let element3 = document.getElementById("carInputBox0");
                        let element8 = document.getElementById("carIcon0");
                        if (element3.classList.contains("d-none") === false) {
                            element3.classList.add("d-none");
                            element8.classList.remove("d-none");
                        }
                    });

                    this.setState({linkMotor: ""}, () => {
                        let element5 = document.getElementById("motorInputBox0");
                        let element9 = document.getElementById("motorIcon0");
                        if (element5.classList.contains("d-none") === false) {
                            element5.classList.add("d-none");
                            element9.classList.remove("d-none");
                        }

                        if(check === true) {
                            // console.log(this.state);
                            fetch(this.state.apiUrl+'/api/trip',
                                {
                                    body: JSON.stringify(this.state),
                                    method: 'POST',
                                    headers: {"Content-type": "application/json;charset=UTF-8"}
                                })
                                .then(res => res.json())
                                .then((data) => {
                                    window.location.href = '/home';
                                    // this.setState({ data: data });
                                });
                        }
                        else{
                            document.getElementById(focus).focus();
                        }
                    });

                });
            });
        }
        else{
            if(check === true) {
                // console.log(this.state);
                fetch(this.state.apiUrl+'/api/trip',
                    {
                        body: JSON.stringify(this.state),
                        method: 'POST',
                        headers: {"Content-type": "application/json;charset=UTF-8"}
                    })
                    .then(res => res.json())
                    .then((data) => {
                        window.location.href = '/home';
                        // this.setState({ data: data });
                    });
            }
            else{
                document.getElementById(focus).focus();
            }

        }




    }

    handleMultipleUpload(files) {
        // console.log(files);

        let url = [];
        files.forEach((file) => {
            url.push(file.cdnUrl);

        });

        this.setState({urls: url});

        // let url = [...this.state.urls];
        // url.push(files);

        this.setState({urls: url}, () => {
            //console.log(this.state.urls);
            if (document.getElementById("imagePreview").scrollLeft !== document.getElementById("imagePreview").scrollWidth) {
                document.getElementById("imagePreview").scrollTo(286*(this.state.urls.length + 1), 0);
            }
        });
    }

    handleMultipleUploadMobile(files) {
        // console.log(files);

        // let url = [...this.state.urls];
        // files.forEach((file) => {
        //     url.push(file.cdnUrl);
        //
        // });
        //
        // this.setState({urls: url});

        let url = [...this.state.urls];
        url.push(files);

        this.setState({urls: url}, () => {
            //console.log(this.state.urls);
            if (document.getElementById("imagePreview").scrollLeft !== document.getElementById("imagePreview").scrollWidth) {
                document.getElementById("imagePreview").scrollTo(286*(this.state.urls.length + 1), 0);
            }
        });
    }

    handleMultipleUploadDay1(files) {

        // let url = [...this.state.urls];
        // url.push(files);

        // this.setState({imageDay1: url}, () => {
        //     // console.log(this.state.urls);
        // });urlsDay
        // let url = [...this.state.urlsDay];
        // url.push(files);
        let url = [];
        files.forEach((file) => {
            url.push(file.cdnUrl);

        });

        this.setState({urlsDay: url}, () => {
            // console.log(this.state.urls);
            console.log(this.state.urlsDay);
            if (document.getElementById("imagePreviewDay").scrollLeft !== document.getElementById("imagePreviewDay").scrollWidth) {
                document.getElementById("imagePreviewDay").scrollTo(195*(this.state.urlsDay.length + 1), 0);
            }
        });
    }

    handleMultipleUploadDay1Mobile(files) {

        // let url = [...this.state.urls];
        // url.push(files);

        // this.setState({imageDay1: url}, () => {
        //     // console.log(this.state.urls);
        // });urlsDay
        let url = [...this.state.urlsDay];
        url.push(files);

        this.setState({urlsDay: url}, () => {
            //console.log(this.state.urls);
            if (document.getElementById("imagePreviewDay").scrollLeft !== document.getElementById("imagePreviewDay").scrollWidth) {
                document.getElementById("imagePreviewDay").scrollTo(195*(this.state.urlsDay.length + 1), 0);
            }
        });
    }

    // handleMultipleUploadDay2(url) {
    //
    //     // let url = [...this.state.urls];
    //     // url.push(files);
    //
    //     this.setState({imageDay2: url}, () => {
    //         // console.log(this.state.urls);
    //     });
    // }

    handleOnDrop() {

    }

    ShowAddImageBox() {

        let elementImage = document.getElementById("image-day");
        if (elementImage.classList.contains("d-none") === false) {
            // elementMotor.classList.add("d-none");
        } else {
            elementImage.classList.remove("d-none");
        }
    }

    toggleProfile(id) {
        var element = document.getElementById("arrow-down-profile");
        var element2 = document.getElementById("arrow-up-profile");
        if(element2.classList.contains("d-none") === false){
            element2.classList.add("d-none");
        }
        else{
            element2.classList.remove("d-none");
        }
        if(element.classList.contains("d-none") === false){
            element.classList.add("d-none");
        }
        else{
            element.classList.remove("d-none");
        }


        let elementProfile = document.getElementById(id);
        if(elementProfile.classList.contains("d-none") === false){
            elementProfile.classList.add("d-none");
        }
        else{
            elementProfile.classList.remove("d-none");
        }

    }

    openModal(){
        this.setState({showModal: true});
    }

    closeModal() {
        this.setState({showModal: false});
    }

    static contextTypes = {
        router: () => true, // replace with PropTypes.object if you use them
    }

    getUrlTimeline(id){
        if(id === 2){
            sessionStorage.setItem("defaultIndex", 1);
            window.location.href = '/home?ctrip=all';
        }
        else{
            sessionStorage.setItem("defaultIndex", 0);
            window.location.href = '/home';
        }

    }

    toggleVisible(){
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 300){
            this.setState({visible: true});

        }
        else if (scrolled <= 300){
            this.setState({visible: false});
        }

        if (scrolled > 50){
            document.getElementById("navMobile").classList.add('bg-transparent-white');
            document.getElementById("navMobile").classList.remove('bg-white');
        }
        else if (scrolled <= 50){
            document.getElementById("navMobile").classList.add('bg-white');
            document.getElementById("navMobile").classList.remove('bg-transparent-white');
        }
    };

    toggleMobile() {
        document.getElementById("sidenavMobile").style.width = "100%";
    }

    closeMobile(){
        document.getElementById("sidenavMobile").style.width = "0";
    }

    iconUploadClick(){
        document.getElementById("fileUploadInput").click();
    }
    render() {
        const editorRef = this.myRef;
        return (
            <div className="bg">
                {/*<div id="slideout_inner" className="slideout d-flex d-md-none" onClick={this.clickSlideOut}>*/}
                {/*    <div className="spinner"><img className="arrow-sideout" src={ArrowSideout} alt="Arrow Sideout"/></div>*/}
                {/*    <div className="body">*/}
                {/*        <a href="/home"><GrHomeRounded className="home-icon"/></a>*/}
                {/*        <a href="#" onClick={this.openModal}><HiOutlineTicket className="ticket-icon"/></a>*/}
                {/*        /!*<a href="/ticket"><HiOutlineTicket className="ticket-icon"/></a>*!/*/}
                {/*    </div>*/}
                {/*</div>*/}

                {/*Desktop show Navbar*/}
                <Container className="d-none d-lg-block">
                    <Navbar color="white" light sticky="top" >
                                <div>
                                    <NavbarBrand role="button" href="/"><img src={Logo} className="logo-img" alt="Profile" /></NavbarBrand><div className="d-none d-md-inline-flex fw-bold">Fridayis</div>
                                    {/*<div className="d-none d-md-inline-flex mx-4"><a href="/ticket"><HiOutlineTicket className="red-circle-border"/></a></div>*/}
                                    <p className="fw-bold title-nav">{this.state.titleNav}</p>
                                </div>
                                <div className="d-flex">
                                    {/*<button type="button" className="btn btn-light button-light-post px-4" id="post-button"*/}
                                    {/*        onClick={(e) => this.saveData(e)}>Post*/}
                                    {/*</button>*/}
                                    {(localStorage.getItem("imageUrl")) ? <NavbarBrand role="button" onClick={this.toggle}><img src={localStorage.getItem("imageUrl")} className="profile-img" alt="Profile" /></NavbarBrand> : <NavbarBrand role="button" onClick={this.toggle}><img src={ProfileImg} className="profile-img" alt="Profile" /></NavbarBrand>}
                                </div>

                                <Collapse isOpen={this.state.isOpen} navbar>
                                    <Nav className="ml-auto nav-logout" navbar>
                                        <NavItem>
                                            <NavLink> <button className="btn btn-dark circle-border" onClickCapture={this.logout}>Logout</button></NavLink>
                                        </NavItem>
                                    </Nav>
                                </Collapse>
                    </Navbar>
                </Container>

                {/*Mobile show Navbar*/}
                <div className="d-block d-lg-none nav-mobile">
                    {/*<Navbar color="white" light sticky="top">*/}
                    <Navbar id="navMobile" className="bg-white" sticky="top" >
                        <div>
                            {/*<NavbarBrand role="button" href="/"><img src={Logo} className="logo-img" alt="Profile" /></NavbarBrand>*/}
                            {(localStorage.getItem("imageUrl")!=="null") ? <NavbarBrand role="button" onClick={this.toggleMobile}><img src={localStorage.getItem("imageUrl")} className="profile-img" alt="Profile" /></NavbarBrand> : <NavbarBrand role="button" onClick={this.toggleMobile}><img src={ProfileImg} className="profile-img" alt="Profile" /></NavbarBrand>}
                            {/*{(localStorage.getItem("username")) && <div className="d-none d-md-inline-flex fw-bold">{localStorage.getItem("username")}</div>}*/}
                            {/*<div className="d-none d-md-inline-flex mx-3"><a href="/ticket"><HiOutlineTicket className="red-circle-border"/></a></div>*/}
                            {/*<div className="d-none d-md-inline-flex mx-3"><a href="/ticket"><HiOutlineTicket className="red-circle-border"/></a></div>*/}
                            <div id="navMobileLogo" className="center-absolute">
                                {(this.state.titleNav)? <p className="pt-1 fw-bold">{this.state.titleNav}</p> : <img src={FridayisLogoMobile} className="logo-img" alt="logo" />}
                            </div>
                            {/*<p className="fw-bold title-nav">{this.state.titleNav}</p>*/}
                        </div>
                        {/*<div className="d-flex">*/}
                            {/*<button className="sarch-btn" onClick={this.openSearch}><img src={IconSearch} className="nav-icon" alt="icon search"/></button>*/}
                            {/*<a id="search-icon" href="#" onClick={this.openSearch}><img src={IconSearch} className="nav-icon" alt="icon search"/></a>*/}
                            {/*<button type="button" className="btn btn-light button-light px-4"*/}
                            {/*        onClick={(e) => this.saveData(e)}>Post*/}
                            {/*</button>*/}
                        {/*</div>*/}

                        {/*<Collapse isOpen={this.state.isOpen} navbar>*/}
                        {/*    <Nav className="ml-auto text-left" navbar>*/}
                        {/*        <NavItem>*/}
                        {/*            <NavLink> <button className="btn btn-dark circle-border" onClickCapture={this.logout}>Logout</button></NavLink>*/}
                        {/*        </NavItem>*/}
                        {/*    </Nav>*/}
                        {/*</Collapse>*/}
                    </Navbar>
                    <div id="sidenavMobile" className="sidenav" >
                        <a href="javascript:void(0)" className="closebtn" onClick={this.closeMobile}><GrFormClose /></a>
                        <Container className="pb-5">
                            <div className="d-flex justify-content-center">
                                {(localStorage.getItem("imageUrl")) ? <img src={localStorage.getItem("imageUrl")} className="profile-img-big" alt="Profile" /> : <img src={ProfileImg} className="profile-img-big" alt="Profile" />}
                            </div>
                            <div className="text-center mt-4">
                                {(localStorage.getItem("username")) && <div className="fw-bold">{localStorage.getItem("username")}</div>}
                            </div>
                            <br/>
                            <button className="btn btn-dark circle-border" onClickCapture={this.logout}>Logout</button>

                            <br/>
                            <div>
                                <div id="profile-toggle" className="">
                                    <Tabs className="Tabs" defaultIndex={sessionStorage.getItem("defaultIndex")} onSelect={(index) => console.log(index)}>
                                        <TabList id="left-tab">
                                            <Tab onClick={this.getUrlTimeline.bind(this, 1)}>Post</Tab>
                                            {/*<Tab><a href="/home?ctrip=all" className="link-default">Invited</a></Tab>*/}
                                            <Tab onClick={this.getUrlTimeline.bind(this, 2)}>Itinerary</Tab>
                                        </TabList>

                                        <TabPanel className="text-left">
                                            <h2 className="new-text">What is going sideways</h2>
                                            <br/>
                                            <p>
                                                Going forward, our dedication is to implement essential and high-quality functions that enhance the user experience, making engagement with our platform smooth and impressive.
                                            </p>
                                            <p>
                                                <span className="fw-bold">Who we are:</span><br/>
                                                We are startups in Sydney, Australia, and Tokyo, Japan. Our mission is to create a platform that enables the sharing of rich travel experiences and information, representing the richness and diversity of the world. We believe that travel has the power to break boundaries and foster shared learning between individuals.
                                            </p>
                                            <p>
                                                <span className="fw-bold"> What we have accomplished:</span><br/>
                                                Users can share their travel stories and itineraries, bringing together unique perspectives from around the world.
                                                We have developed a system that allows your followers to visit the platform and invite their friends to join their journey.
                                                During this time, users will have the opportunity to learn how to use this platform through its initial functions. This learning process will familiarize your followers with the platform, reducing mistakes in the future.
                                            </p>
                                            <p>

                                                <span className="fw-bold">Here's what we're currently working on:</span><br/>
                                                Once your followers have a clear understanding of the core functions of the platform, they will be able to book tickets and accommodations directly through it.
                                                We are developing a feature that will allow bloggers like you to track the revenue generated from your referral bookings and other necessary functions that benefit both you and your followers.
                                            </p>
                                            <p>
                                                Note: While waiting for the booking process, you will have the opportunity to earn money through Google Ads.
                                            </p>
                                            <p>
                                                We are excited about future developments and It would be a great honor for us to join you on the same ship.
                                            </p>
                                        </TabPanel>
                                        <TabPanel className="text-left">
                                            <h2 className="new-text">What is going sideways</h2>
                                            {/*<br/>*/}
                                            {/*<div className="row">*/}
                                            {/*    <div className="col-4"><span className="fw-bold">Who</span><br />we are</div>*/}
                                            {/*    <div className="col-4"><span className="fw-bold">What</span><br />we have done</div>*/}
                                            {/*    <div className="col-4"><span className="fw-bold">Which</span><br />is going on</div>*/}
                                            {/*</div>*/}
                                            <br/>
                                            <p>
                                                Going forward, our dedication is to implement essential and high-quality functions that enhance the user experience, making engagement with our platform smooth and impressive.
                                            </p>
                                            <p>
                                                <span className="fw-bold">Who we are:</span><br/>
                                                We are startups in Sydney, Australia, and Tokyo, Japan. Our mission is to create a platform that enables the sharing of rich travel experiences and information, representing the richness and diversity of the world. We believe that travel has the power to break boundaries and foster shared learning between individuals.
                                            </p>
                                            <p>
                                                <span className="fw-bold"> What we have accomplished:</span><br/>
                                                Users can share their travel stories and itineraries, bringing together unique perspectives from around the world.
                                                We have developed a system that allows your followers to visit the platform and invite their friends to join their journey.
                                                During this time, users will have the opportunity to learn how to use this platform through its initial functions. This learning process will familiarize your followers with the platform, reducing mistakes in the future.
                                            </p>
                                            <p>

                                                <span className="fw-bold">Here's what we're currently working on:</span><br/>
                                                Once your followers have a clear understanding of the core functions of the platform, they will be able to book tickets and accommodations directly through it.
                                                We are developing a feature that will allow bloggers like you to track the revenue generated from your referral bookings and other necessary functions that benefit both you and your followers.
                                            </p>
                                            <p>
                                                Note: While waiting for the booking process, you will have the opportunity to earn money through Google Ads.
                                            </p>
                                            <p>
                                                We are excited about future developments and It would be a great honor for us to join you on the same ship.
                                            </p>
                                        </TabPanel>
                                    </Tabs>
                                </div>
                                <div className="copy-right-mobile position-fixed bottom-0">
                                    <p>Fridayis 2023 ©</p>
                                </div>

                            </div>
                        </Container>
                    </div>

                    <div className="post-button-bar">
                            <a id="back-icon" href="/home"><IoChevronBackCircleOutline className="nav-icon" /></a>
                            {/*<button type="button" className="btn btn-light button-light-post px-4 right-0" disabled="true" id="post-button-mobile"*/}
                            {/*        onClick={(e) => this.saveData(e)}>Post*/}
                            {/*</button>*/}
                    </div>
                </div>



                <Modal
                    isOpen={this.state.showModal}
                    contentLabel="ComingSoon"
                    className="modelComingSoon"
                >
                    <div className="text-right">
                        <IoIosClose onClick={this.closeModal} style={{"font-size": "50px","cursor": "pointer"}}/>
                    </div>
                    <img src={ComingSoon} width="100%" />
                </Modal>

                {/*Show*/}
                <br className="d-block d-lg-none" />
                <br className="d-block d-lg-none" />
                <Container className="row">
                    <div className="col-12 col-lg-4 left-section d-none d-lg-flex">
                        <Container className="py-5">
                            <div className="d-flex justify-content-center">
                                {(localStorage.getItem("imageUrl")) ? <img src={localStorage.getItem("imageUrl")} className="profile-img-big" alt="Profile" /> : <img src={ProfileImg} className="profile-img-big" alt="Profile" />}
                            </div>
                            <div className="text-center mt-4">
                                {(localStorage.getItem("username")) && <div className="fw-bold">{localStorage.getItem("username")}</div>}
                            </div>
                            <br/>
                            <div className="mx-3">
                                {/*{(this.state.userInfo) &&*/}
                                {/*    <div>*/}
                                {/*        {(this.state.userInfo.passportNo) && <p className="fw-bold">Passport No {this.state.userInfo.passportNo}</p>}*/}
                                {/*        {(this.state.passportExpire) && <p>Expire {this.state.passportExpire} Day left</p>}*/}
                                {/*    </div>*/}
                                {/*}*/}


                                {/*<div className="text-center my-3">*/}
                                {/*    <IoIosArrowDown id={"arrow-down-profile"} className="d-none" style={{"cursor": "pointer"}} onClick={this.toggleProfile.bind(this, "profile-toggle")}/>*/}
                                {/*    <IoIosArrowUp id={"arrow-up-profile"} style={{"cursor": "pointer"}} onClick={this.toggleProfile.bind(this, "profile-toggle")}/>*/}
                                {/*</div>*/}

                                <div id="profile-toggle" className="">
                                    {/*<div className="row">*/}
                                    {/*    <div className="col-6 text-center">*/}
                                    {/*        <p>0 Following</p>*/}
                                    {/*    </div>*/}
                                    {/*    <div className="col-6 text-center">*/}
                                    {/*        <p>0 Follower</p>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    {/*<Tabs className="Tabs" defaultIndex={2} onSelect={(index) => console.log(index)}>*/}
                                    {/*            <TabList>*/}
                                    {/*                <Tab><a href="/home?ctrip=all">Invited</a></Tab>*/}
                                    {/*                <Tab>Post</Tab>*/}
                                    {/*                <Tab>Earnings & Point</Tab>*/}
                                    {/*                <Tab>Saved</Tab>*/}
                                    {/*            </TabList>*/}

                                    {/*                <TabPanel>*/}

                                    {/*                </TabPanel>*/}
                                    {/*    <TabPanel>*/}
                                    {/*        <img src={ComingSoon} width="100%" />*/}
                                    {/*    </TabPanel>*/}
                                    {/*    <TabPanel>*/}
                                    {/*        <img src={EarningMockup} alt="Earning" width="100%" onClick={this.openModal} style={{"cursor": "pointer"}}/>*/}

                                    {/*        /!*                                            <button type="button" className="btn btn-info btn-lg" data-toggle="modal"*!/*/}
                                    {/*        /!*        data-target="#myModal">Open Large Modal*!/*/}
                                    {/*        /!*</button>*!/*/}
                                    {/*        /!*<div class="modal fade" id="myModal" role="dialog">*!/*/}
                                    {/*        /!*    <div class="modal-dialog modal-lg">*!/*/}
                                    {/*        /!*        <div class="modal-content">*!/*/}
                                    {/*        /!*            <div class="modal-header">*!/*/}
                                    {/*        /!*                <button type="button" class="close" data-dismiss="modal">&times;</button>*!/*/}
                                    {/*        /!*            </div>*!/*/}
                                    {/*        /!*            <div class="modal-body">*!/*/}
                                    {/*        /!*                <img src={ComingSoon} width="100%" />*!/*/}
                                    {/*        /!*            </div>*!/*/}
                                    {/*        /!*        </div>*!/*/}
                                    {/*        /!*    </div>*!/*/}
                                    {/*        /!*</div>*!/*/}

                                    {/*    </TabPanel>*/}
                                    {/*    <TabPanel><img src={ComingSoon} width="100%" /></TabPanel>*/}
                                    {/*</Tabs>*/}
                                    <Tabs className="Tabs">
                                        <TabList id="left-tab">
                                            <Tab onClick={this.getUrlTimeline.bind(this, 1)}>Post</Tab>
                                            {/*<Tab><a href="/home?ctrip=all" className="link-default">Invited</a></Tab>*/}
                                            <Tab onClick={this.getUrlTimeline.bind(this, 2)}>Itinerary</Tab>
                                        </TabList>

                                        <TabPanel>
                                            <h2 className="new-text">What is going sideways</h2>
                                            <br/>
                                            {/*<div className="row">*/}
                                            {/*    <div className="col-4"><span className="fw-bold">Who</span><br />we are</div>*/}
                                            {/*    <div className="col-4"><span className="fw-bold">What</span><br />we have done</div>*/}
                                            {/*    <div className="col-4"><span className="fw-bold">Which</span><br />is going on</div>*/}
                                            {/*</div>*/}
                                            {/*<br/>*/}
                                            <p>
                                                Going forward, our dedication is to implement essential and high-quality functions that enhance the user experience, making engagement with our platform smooth and impressive.
                                            </p>
                                            <p>
                                                <span className="fw-bold">Who we are:</span><br/>
                                                We are startups in Sydney, Australia, and Tokyo, Japan. Our mission is to create a platform that enables the sharing of rich travel experiences and information, representing the richness and diversity of the world. We believe that travel has the power to break boundaries and foster shared learning between individuals.
                                            </p>
                                            <p>
                                                <span className="fw-bold"> What we have accomplished:</span><br/>
                                                Users can share their travel stories and itineraries, bringing together unique perspectives from around the world.
                                                We have developed a system that allows your followers to visit the platform and invite their friends to join their journey.
                                                During this time, users will have the opportunity to learn how to use this platform through its initial functions. This learning process will familiarize your followers with the platform, reducing mistakes in the future.
                                            </p>
                                            <p>

                                                <span className="fw-bold">Here's what we're currently working on:</span><br/>
                                                Once your followers have a clear understanding of the core functions of the platform, they will be able to book tickets and accommodations directly through it.
                                                We are developing a feature that will allow bloggers like you to track the revenue generated from your referral bookings and other necessary functions that benefit both you and your followers.
                                            </p>
                                            <p>
                                                Note: While waiting for the booking process, you will have the opportunity to earn money through Google Ads.
                                            </p>
                                            <p>
                                                We are excited about future developments and It would be a great honor for us to join you on the same ship.
                                            </p>
                                        </TabPanel>
                                        <TabPanel>
                                            <h2 className="new-text">What is going sideways</h2>
                                            <br/>
                                            {/*<div className="row">*/}
                                            {/*    <div className="col-4"><span className="fw-bold">Who</span><br />we are</div>*/}
                                            {/*    <div className="col-4"><span className="fw-bold">What</span><br />we have done</div>*/}
                                            {/*    <div className="col-4"><span className="fw-bold">Which</span><br />is going on</div>*/}
                                            {/*</div>*/}
                                            {/*<br/>*/}
                                            <p>
                                                Going forward, our dedication is to implement essential and high-quality functions that enhance the user experience, making engagement with our platform smooth and impressive.
                                            </p>
                                            <p>
                                                <span className="fw-bold">Who we are:</span><br/>
                                                We are startups in Sydney, Australia, and Tokyo, Japan. Our mission is to create a platform that enables the sharing of rich travel experiences and information, representing the richness and diversity of the world. We believe that travel has the power to break boundaries and foster shared learning between individuals.
                                            </p>
                                            <p>
                                                <span className="fw-bold"> What we have accomplished:</span><br/>
                                                Users can share their travel stories and itineraries, bringing together unique perspectives from around the world.
                                                We have developed a system that allows your followers to visit the platform and invite their friends to join their journey.
                                                During this time, users will have the opportunity to learn how to use this platform through its initial functions. This learning process will familiarize your followers with the platform, reducing mistakes in the future.
                                            </p>
                                            <p>

                                                <span className="fw-bold">Here's what we're currently working on:</span><br/>
                                                Once your followers have a clear understanding of the core functions of the platform, they will be able to book tickets and accommodations directly through it.
                                                We are developing a feature that will allow bloggers like you to track the revenue generated from your referral bookings and other necessary functions that benefit both you and your followers.
                                            </p>
                                            <p>
                                                Note: While waiting for the booking process, you will have the opportunity to earn money through Google Ads.
                                            </p>
                                            <p>
                                                We are excited about future developments and It would be a great honor for us to join you on the same ship.
                                            </p>
                                        </TabPanel>
                                    </Tabs>
                                </div>
                                <br/><br/>
                                <div className="copy-right-desktop position-fixed bottom-0">
                                    <p>Fridayis 2023 ©</p>
                                </div>

                            </div>


                        </Container>
                    </div>
                    <div className="col-12 col-lg-8 right-section">
                        <div>
                            <Container className="py-5">
                                {/*<div className="row">*/}
                                    {/*<div className="col-8 col-md-9 col-lg-11"><a href="/home"><IoChevronBackSharp className="red-circle-border"/></a></div>*/}
                                    {/*<div className="col-4 col-md-3 col-lg-1 text-right">*/}
                                    {/*    <button type="button" className="btn btn-light button-light form-control" onClick={()=>this.saveData()}>Post</button>*/}
                                    {/*</div>*/}
                                {/*</div>*/}

                                <div className="row pt-2">
                                    <div className="col-12 pt-2">
                                        <FormGroup>
                                            {/*<Label for="tripDestination">Destination</Label>*/}
                                            <Input type="text" className="border-0 input-no-outline" name="tripDestination"
                                                   id="tripDestination" value={this.state.tripDestination}
                                                   onChange={this.handleChange} placeholder="Destination"/>
                                            <Label for="checkTripDestination" style={{display:  "none",color:"red"}} id ="checkTripDestination">Please Enter Destination </Label>
                                        </FormGroup>
                                        <FormGroup>
                                            {/*<Label for="tripName">Title</Label>*/}
                                            <Input type="text" className="border-0 input-no-outline" name="tripName"
                                                   id="tripName" value={this.state.tripName} onChange={this.handleChange}
                                                   placeholder="Title" autocomplete="off"/>
                                            <Label for="checkTripName" style={{display:  "none",color:"red"}} id ="checkTripName">Please Enter Title </Label>
                                        </FormGroup>
                                        <FormGroup>
                                    <textarea className="form-control border-0 input-no-outline" id="tripDescription"
                                              name="tripDescription" rows="2" onChange={this.handleChange}
                                              placeholder="Caption"></textarea>
                                            <Label for="checkTripDescription" style={{display:  "none",color:"red"}} id ="checkTripDescription">Please Enter Caption </Label>
                                        </FormGroup>

                                        <div className="row">
                                            <div id="flightIconflightUrl" className="col-2 col-lg-1 mb-3">
                                                {/*<div className="input-group mb-3 ">*/}
                                                <div className="input-group-prepend">
                                                    <img
                                                        src={window.location.protocol + "//" + window.location.host + "/icon/airplane.png"}
                                                        className="icon-input-before" name="linkFlightButton"
                                                        id="linkFlightButton"
                                                        onClick={this.clickLinkFlight.bind(this, 'flightUrl')} alt="Airplane"/>
                                                </div>
                                            </div>
                                            <div id="flightInputBoxflightUrl" className="col-6 col-lg-3 mb-3 d-none">
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <img
                                                            src={window.location.protocol + "//" + window.location.host + "/icon/airplane.png"}
                                                            className="icon-input-update" name="linkFlightButton"
                                                            id="linkFlightButton"
                                                            onClick={this.clickLinkFlight.bind(this, 'flightUrl')} alt="Airplane"/>
                                                    </div>
                                                    <Input type="text" name="flightUrl" id="flightUrl"
                                                           className="form-control input-link-update-input" value={this.state.flightUrl}
                                                           onChange={this.handleChange} aria-label="linkFlight"
                                                           placeholder="Destination"
                                                           aria-describedby="linkFlightButton"/>
                                                </div>
                                            </div>

                                            <div id="hotelIconhotelUrl" className="col-2 col-lg-1 mb-3">
                                                <div className="input-group-prepend">
                                                    <img
                                                        src={window.location.protocol + "//" + window.location.host + "/icon/double-bed.png"}
                                                        className="icon-input-before" name="linkHotelButton"
                                                        id="linkHotelButton" onClick={this.clickLinkHotel.bind(this, 'hotelUrl')}
                                                        alt="Hotel"/>
                                                </div>
                                            </div>
                                            <div id="hotelInputBoxhotelUrl" className="col-6 col-lg-3 mb-3 d-none">
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <img
                                                            src={window.location.protocol + "//" + window.location.host + "/icon/double-bed.png"}
                                                            className="icon-input-update" name="linkHotelButton"
                                                            id="linkHotelButton" onClick={this.clickLinkHotel.bind(this, 'hotelUrl')}
                                                            alt="Hotel"/>
                                                    </div>
                                                    <Input type="text" name="hotelUrl" id="hotelUrl"
                                                           className="form-control input-link-update-input" value={this.state.hotelUrl}
                                                           onChange={this.handleChange} aria-label="linkHotel"
                                                           placeholder="Hotel name"
                                                           aria-describedby="linkHotelButton"/>
                                                </div>

                                            </div>

                                            <div id="carIconcarUrl" className="col-2 col-lg-1 mb-3">
                                                <div className="input-group-prepend">
                                                    <img
                                                        src={window.location.protocol + "//" + window.location.host + "/icon/sedan.png"}
                                                        className="icon-input-before" name="linkCarButton" id="linkCarButton"
                                                        onClick={this.clickLinkCar.bind(this, 'carUrl')} alt="Car"/>
                                                </div>
                                            </div>
                                            <div id="carInputBoxcarUrl" className="col-6 col-lg-3 mb-3 d-none">
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <img
                                                            src={window.location.protocol + "//" + window.location.host + "/icon/sedan.png"}
                                                            className="icon-input-update" name="linkCarButton" id="linkCarButton"
                                                            onClick={this.clickLinkCar.bind(this, 'carUrl')} alt="Car"/>
                                                    </div>
                                                    <Input type="text" name="carUrl" id="carUrl" className="form-control input-link-update-input"
                                                           value={this.state.carUrl} onChange={this.handleChange}
                                                           placeholder="Pick up location"
                                                           aria-label="linkCar" aria-describedby="linkCarButton"/>
                                                </div>

                                            </div>

                                            <div id="motorIconmotorUrl" className="col-2 col-lg-1 mb-3">
                                                <div className="input-group-prepend">
                                                    <img
                                                        src={window.location.protocol + "//" + window.location.host + "/icon/motorbike.png"}
                                                        className="icon-input-before" name="linkMotorButton"
                                                        id="linkMotorButton" onClick={this.clickLinkMotor.bind(this, 'motorUrl')}
                                                        alt="Motor"/>
                                                </div>
                                            </div>
                                            <div id="motorInputBoxmotorUrl" className="col-6 col-lg-3 mb-3 d-none">
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <img
                                                            src={window.location.protocol + "//" + window.location.host + "/icon/motorbike.png"}
                                                            className="icon-input-update" name="linkMotorButton"
                                                            id="linkMotorButton" onClick={this.clickLinkMotor.bind(this, 'motorUrl')}
                                                            alt="Motor"/>
                                                    </div>
                                                    <Input type="text" name="motorUrl" id="motorUrl"
                                                           className="form-control input-link-update-input" value={this.state.motorUrl}
                                                           onChange={this.handleChange} aria-label="linkMotor"
                                                           placeholder="Pick up location"
                                                           aria-describedby="linkMotorButton"/>
                                                </div>

                                            </div>
                                        </div>
                                        <br/>
                                        <br/>



                                        {/*<div className="d-none d-md-flex">*/}
                                        <div className="overflow-auto d-flex" id="imagePreview">
                                            {(this.state.urls.length > 0) && this.state.urls?.map((file) => (<LazyLoadImage effect="blur" fetchpriority="high"  src={file} className="upload-img-preview-286" alt="Upload preview"/>))}

                                            <SimpleFileUpload id="fileUploadInput"  apiKey="1b4bc7ff40db0324e5fabecd4c5563e2" width="286" height="286"
                                                              onSuccess={this.handleMultipleUpload} onDrop={this.handleOnDrop} preview={false} multiple={true}
                                                              accepted="image/*"
                                                              removeLinks={true}
                                                              buttonClass="upload-img-icon-bg-286"
                                                              buttonText={<div><img src={IconImage} className="upload-img-icon"/><p>Click to upload</p></div>}
                                            />

                                        </div>

                                        {/*</div>*/}

                                        {/*<div className="d-flex d-md-none">*/}

                                        {/*    {(this.state.urls.length > 0) ?*/}
                                        {/*        <div className="overflow-auto d-flex" id="imagePreview">*/}
                                        {/*            {this.state.urls?.map((file) => (*/}
                                        {/*                <LazyLoadImage effect="blur" fetchpriority="high"  src={file} className="upload-img-preview-286" alt="Upload preview"/>))}*/}
                                        {/*            <SimpleFileUpload apiKey="1b4bc7ff40db0324e5fabecd4c5563e2" width="286"*/}
                                        {/*                              height="286" onSuccess={this.handleMultipleUploadMobile}*/}
                                        {/*                              onDrop={this.handleOnDrop} preview={false}*/}
                                        {/*                              accepted="image/*"/>*/}
                                        {/*            /!*{(this.state.urls.length < 3) && <SimpleFileUpload apiKey="1b4bc7ff40db0324e5fabecd4c5563e2" width="286" height="286" onSuccess={this.handleMultipleUpload} onDrop={this.handleOnDrop} preview={false} accepted="image/*" />}*!/*/}
                                        {/*        </div>*/}
                                        {/*        :*/}
                                        {/*        <div className="text-center">*/}
                                        {/*            <SimpleFileUpload apiKey="1b4bc7ff40db0324e5fabecd4c5563e2" width="286" height="286"*/}
                                        {/*                              onSuccess={this.handleMultipleUploadMobile} onDrop={this.handleOnDrop} preview={false}*/}
                                        {/*                              accepted="image/*"/>*/}
                                        {/*        </div>*/}
                                        {/*    }*/}

                                        {/*</div>*/}

                                    </div>
                                </div>


                                    {/*<div className="col-2">*/}
                                    {/*    <div className="line-timeline"></div>*/}
                                    {/*    <button type="button" className="btn btn-light button-plus form-control"*/}
                                    {/*            onClick={() => this.saveDataDay()}><FaPlus aria-hidden="true"/></button>*/}

                                {/*<div className="position-absolute">*/}
                                <div className="add-day-bar">
                                    <button type="button" className="btn btn-light add-day-button"
                                            onClick={() => this.saveDataDay()}><span className="fw-bold">+Day</span></button>
                                    <button type="button" className="btn btn-light add-day-post-button mx-2" id="post-button"
                                            onClick={(e) => this.saveData(e)}><span className="fw-bold">Post</span>
                                    </button>
                                </div>
                                {/*</div>*/}




                                    {/*</div>*/}

                                    {/*<div className="col-10">*/}
                                        {this.state.dayList.map((row, rowIndex) => (
                                            <div className="row">
                                                <div className="line-col col-2">
                                                    <div className="line-timeline"> </div>
                                                </div>
                                                <div className="col-10">
                                                    <Row className="day-timeline my-5">
                                                    <Col xs="12" sm="12">
                                                        <FormGroup>
                                                            <p className="fw-bold">Day {rowIndex + 1}</p>
                                                        </FormGroup>
                                                        {(row[7].length > 0) &&
                                                            <div className="overflow-auto d-flex mb-3">
                                                                {row[7]?.map((fileImageDay, indexImageDay) => (
                                                                    <img src={fileImageDay} className="show-img-preview-195" alt={'Day ' + (rowIndex + 1) + ' image '+(indexImageDay + 1)}/>
                                                                ))}
                                                            </div>
                                                        }

                                                        {/*<div className="overflow-auto d-flex mb-3">*/}
                                                        {/*    {(row[7]) && <img src={row[7]} className="show-img-preview-195"*/}
                                                        {/*                      alt={'Day ' + (rowIndex + 1) + ' image 1'}/>}*/}
                                                        {/*    {(row[8]) && <img src={row[8]} className="show-img-preview-195"*/}
                                                        {/*                      alt={'Day ' + (rowIndex + 1) + ' image 2'}/>}*/}
                                                        {/*</div>*/}
                                                        <div>
                                                            {ReactHtmlParser(row[1])}
                                                        </div>
                                                        <div className="row mb-3">
                                                            {row[2] && (
                                                                <div id={"flightIcon" + (rowIndex + 1)} className="col-2 col-lg-1 mb-3 d-none">
                                                                    <div className="input-group-prepend">
                                                                        <img
                                                                            src={window.location.protocol + "//" + window.location.host + "/icon/airplane.png"}
                                                                            className="icon-input-before" name="linkFlightButton"
                                                                            id="linkFlightButton"
                                                                            onClick={this.clickLinkFlight.bind(this, (rowIndex + 1))}
                                                                            alt="Airplane"/>
                                                                    </div>
                                                                </div>
                                                            )}
                                                            {row[2] && (
                                                                <div id={"flightInputBox" + (rowIndex + 1)} className="col-6 col-lg-3 mb-3">
                                                                    <div className="input-group">
                                                                        <div className="input-group-prepend">
                                                                            <img
                                                                                src={window.location.protocol + "//" + window.location.host + "/icon/airplane.png"}
                                                                                className="icon-input-update" name="linkMotorButton"
                                                                                id="linkMotorButton" onClick={this.clickLinkFlight.bind(this, (rowIndex + 1))}
                                                                                alt="Motor"/>
                                                                        </div>
                                                                        <Input type="text" name="linkFlight" id="linkFlight"
                                                                               className="form-control input-link-update" value={row[2]}
                                                                               aria-label="linkFlight"
                                                                               aria-describedby="linkFlightButton"/>
                                                                    </div>
                                                                </div>
                                                            )}
                                                            {row[3] && (
                                                                <div id={"hotelIcon" + (rowIndex + 1)} className="col-2 col-lg-1 mb-3 d-none">
                                                                    <div className="input-group-prepend">
                                                                        <img
                                                                            src={window.location.protocol + "//" + window.location.host + "/icon/double-bed.png"}
                                                                            className="icon-input-before" name="linkHotelButton"
                                                                            id="linkHotelButton"
                                                                            onClick={this.clickLinkHotel.bind(this, (rowIndex + 1))}
                                                                            alt="Hotel"/>
                                                                    </div>
                                                                </div>
                                                            )}
                                                            {row[3] && (
                                                                <div id={"hotelInputBox" + (rowIndex + 1)}
                                                                     className="col-6 col-lg-3 mb-3">
                                                                    <div className="input-group">
                                                                        <div className="input-group-prepend">
                                                                            <img
                                                                                src={window.location.protocol + "//" + window.location.host + "/icon/double-bed.png"}
                                                                                className="icon-input-update" name="linkMotorButton"
                                                                                id="linkMotorButton" onClick={this.clickLinkHotel.bind(this, (rowIndex + 1))}
                                                                                alt="Motor"/>
                                                                        </div>
                                                                        <Input type="text" name="linkHotel" id="linkHotel"
                                                                               className="form-control input-link-update" value={row[3]}
                                                                               aria-label="linkHotel"
                                                                               aria-describedby="linkHotelButton"/>
                                                                    </div>

                                                                </div>
                                                            )}
                                                            {row[4] && (
                                                                <div id={"carIcon" + (rowIndex + 1)} className="col-2 col-lg-1 mb-3 d-none">
                                                                    <div className="input-group-prepend">
                                                                        <img
                                                                            src={window.location.protocol + "//" + window.location.host + "/icon/sedan.png"}
                                                                            className="icon-input-before" name="linkCarButton"
                                                                            id="linkCarButton"
                                                                            onClick={this.clickLinkCar.bind(this, (rowIndex + 1))}
                                                                            alt="Car"/>
                                                                    </div>
                                                                </div>
                                                            )}
                                                            {row[4] && (
                                                                <div id={"carInputBox" + (rowIndex + 1)}
                                                                     className="col-6 col-lg-3 mb-3">
                                                                    <div className="input-group">
                                                                        <div className="input-group-prepend">
                                                                            <img
                                                                                src={window.location.protocol + "//" + window.location.host + "/icon/sedan.png"}
                                                                                className="icon-input-update" name="linkMotorButton"
                                                                                id="linkMotorButton" onClick={this.clickLinkCar.bind(this, (rowIndex + 1))}
                                                                                alt="Motor"/>
                                                                        </div>
                                                                        <Input type="text" name="linkCar" id="linkCar"
                                                                               className="form-control input-link-update" value={row[4]}
                                                                               aria-label="linkCar" aria-describedby="linkCarButton"/>
                                                                    </div>

                                                                </div>
                                                            )}
                                                            {/*{row[5] && (*/}
                                                            {/*    <div className="col-2 col-lg-1 mb-3">*/}
                                                            {/*        <div className="input-group-prepend">*/}
                                                            {/*            <MdTrain className="icon-input" name="linkTrainButton" id="linkTrainButton" onClick={this.clickLinkTrain.bind(this, (rowIndex+1))}/>*/}
                                                            {/*        </div>*/}
                                                            {/*    </div>*/}
                                                            {/*)}*/}
                                                            {/*{row[5] && (*/}
                                                            {/*    <div id={"trainInputBox"+(rowIndex+1)} className="col-4 col-lg-3 mb-3 d-none">*/}
                                                            {/*        <Input type="text" name="linkTrain" id="linkTrain" className="input-link" value={row[5]} aria-label="linkTrain" aria-describedby="linkTrainButton"/>*/}
                                                            {/*    </div>*/}
                                                            {/*)}*/}
                                                            {row[6] && (
                                                                <div id={"motorIcon" + (rowIndex + 1)} className="col-2 col-lg-1 mb-3 d-none">
                                                                    <div className="input-group-prepend">
                                                                        <img
                                                                            src={window.location.protocol + "//" + window.location.host + "/icon/motorbike.png"}
                                                                            className="icon-input-before" name="linkMotorButton"
                                                                            id="linkMotorButton"
                                                                            onClick={this.clickLinkMotor.bind(this, (rowIndex + 1))}
                                                                            alt="Motor"/>
                                                                    </div>
                                                                </div>
                                                            )}
                                                            {row[6] && (
                                                                <div id={"motorInputBox" + (rowIndex + 1)}
                                                                     className="col-6 col-lg-3 mb-3">
                                                                    <div className="input-group">
                                                                        <div className="input-group-prepend">
                                                                            <img
                                                                                src={window.location.protocol + "//" + window.location.host + "/icon/motorbike.png"}
                                                                                className="icon-input-update" name="linkMotorButton"
                                                                                id="linkMotorButton" onClick={this.clickLinkMotor.bind(this, (rowIndex + 1))}
                                                                                alt="Motor"/>
                                                                        </div>
                                                                        <Input type="text" name="linkMotor" id="linkMotor"
                                                                               className="form-control input-link-update" value={row[6]}
                                                                               aria-label="linkMotor"
                                                                               aria-describedby="linkMotorButton"/>
                                                                    </div>

                                                                </div>
                                                            )}
                                                        </div>
                                                    </Col>
                                                </Row>
                                                </div>
                                            </div>
                                        ))}

                                        {/* Post */}
                                        <div className="row mb-5">
                                            <div className="line-col col-2">
                                                <div className="line-timeline-last-child"> </div>
                                            </div>
                                            <div className="col-10">
                                                <Row className="day-timeline my-5">
                                                <Col xs="12" sm="12">
                                                    <FormGroup>
                                                        <p className="fw-bold">Day {parseInt(this.state.day) + 1}</p>
                                                    </FormGroup>
                                                    <FormGroup className="mt-2 p-0">
                                                        <Editor
                                                            id="contentDescription"
                                                            name="contentDescription"
                                                            onEditorChange={this.handleEditorChange}
                                                            initialValue={this.props.initialValue}
                                                            value={this.state.contentDescription}
                                                            onInit={(evt, editor) => editorRef.current = editor}
                                                            // initialValue=""
                                                            init={{
                                                                // skin: "oxide-dark",
                                                                placeholder: "How it like",
                                                                // height: 100,
                                                                menubar: false,
                                                                plugins: [
                                                                    'autoresize',
                                                                    'advlist autolink lists link image charmap print preview anchor',
                                                                    'searchreplace visualblocks code fullscreen',
                                                                    'insertdatetime media table paste code help wordcount'
                                                                ],
                                                                toolbar: false,
                                                                branding: false,
                                                                statusbar: false,
                                                                // toolbar: 'undo redo | formatselect | ' +
                                                                //     'bold italic backcolor | alignleft aligncenter ' +
                                                                //     'alignright alignjustify | bullist numlist outdent indent | ' +
                                                                //     'removeformat | help',
                                                                // content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                                            }}
                                                        />
                                                        <Label for="checkContentDescription" style={{display:  "none",color:"red"}} id ="checkContentDescription">Please Enter Day {parseInt(this.state.day) + 1} content </Label>
                                                    </FormGroup>

                                                    {/*{(this.state.imageDay1 && this.state.imageDay2) ?*/}
                                                    {/*    <div id={'image-day'} className="overflow-auto d-flex">*/}
                                                    {/*        <img src={this.state.imageDay1} className="upload-img-preview-195"*/}
                                                    {/*             alt="Upload preview"/>*/}
                                                    {/*        <img src={this.state.imageDay2} className="upload-img-preview-195"*/}
                                                    {/*             alt="Upload preview"/>*/}
                                                    {/*    </div>*/}
                                                    {/*    : (this.state.imageDay1) ?*/}
                                                    {/*        <div id={'image-day'} className="overflow-auto d-flex">*/}
                                                    {/*            <img src={this.state.imageDay1} className="upload-img-preview-195"*/}
                                                    {/*                 alt="Upload preview"/>*/}
                                                    {/*            <span className="image-day"><SimpleFileUpload*/}
                                                    {/*                apiKey="1b4bc7ff40db0324e5fabecd4c5563e2" width="195"*/}
                                                    {/*                height="195" onSuccess={this.handleMultipleUploadDay2}*/}
                                                    {/*                onDrop={this.handleOnDrop} preview={false} accepted="image/*"/></span>*/}
                                                    {/*        </div>*/}
                                                    {/*        : <div className="text-center">*/}
                                                    {/*            <SimpleFileUpload apiKey="1b4bc7ff40db0324e5fabecd4c5563e2"*/}
                                                    {/*                              width="195" height="195"*/}
                                                    {/*                              onSuccess={this.handleMultipleUploadDay1}*/}
                                                    {/*                              onDrop={this.handleOnDrop} preview={false}*/}
                                                    {/*                              accepted="image/*"/>*/}
                                                    {/*        </div>}*/}
                                                    <div className="overflow-auto d-flex" id="imagePreviewDay">
                                                        {(this.state.urlsDay.length > 0) && this.state.urlsDay?.map((file) => (<LazyLoadImage effect="blur" fetchpriority="high"  src={file} className="upload-img-preview-195" alt="Upload preview"/>))}
                                                        <SimpleFileUpload id={'upload'+(parseInt(this.state.day) + 1)} apiKey="1b4bc7ff40db0324e5fabecd4c5563e2" width="195" height="195" onSuccess={this.handleMultipleUploadDay1} onDrop={this.handleOnDrop}
                                                                          preview={false} accepted="image/*"
                                                                          removeLinks={true} multiple={true}
                                                                          buttonClass="upload-img-icon-bg-195"
                                                                          buttonText={<div><img src={IconImage} className="upload-img-icon-195"/><p>Click to upload</p></div>}/>

                                                    </div>

                                                    <div className="row mt-2">
                                                        <div id="flightIcon0" className="col-2 col-lg-1 mb-3">
                                                            {/*<div className="input-group mb-3 ">*/}
                                                            <div className="input-group-prepend">
                                                                <img
                                                                    src={window.location.protocol + "//" + window.location.host + "/icon/airplane.png"}
                                                                    className="icon-input-before" name="linkFlightButton"
                                                                    id="linkFlightButton"
                                                                    onClick={this.clickLinkFlight.bind(this, 0)} alt="Airplane"/>
                                                            </div>
                                                        </div>
                                                        <div id="flightInputBox0" className="col-6 col-lg-3 mb-3 d-none">
                                                            <div className="input-group">
                                                                <div className="input-group-prepend">
                                                                    <img
                                                                        src={window.location.protocol + "//" + window.location.host + "/icon/airplane.png"}
                                                                        className="icon-input-update" name="linkFlightButton"
                                                                        id="linkFlightButton"
                                                                        onClick={this.clickLinkFlight.bind(this, 0)} alt="Airplane"/>
                                                                </div>
                                                                <Input type="text" name="linkFlight" id="linkFlight"
                                                                       className="form-control input-link-update-input" value={this.state.linkFlight}
                                                                       onChange={this.handleChange} aria-label="linkFlight"
                                                                       placeholder="Destination"
                                                                       aria-describedby="linkFlightButton"/>
                                                            </div>
                                                        </div>
                                                        <div id="hotelIcon0" className="col-2 col-lg-1 mb-3">
                                                            <div className="input-group-prepend">
                                                                <img
                                                                    src={window.location.protocol + "//" + window.location.host + "/icon/double-bed.png"}
                                                                    className="icon-input-before" name="linkHotelButton"
                                                                    id="linkHotelButton" onClick={this.clickLinkHotel.bind(this, 0)}
                                                                    alt="Hotel"/>
                                                            </div>
                                                        </div>
                                                        <div id="hotelInputBox0" className="col-6 col-lg-3 mb-3 d-none">
                                                            <div className="input-group">
                                                                <div className="input-group-prepend">
                                                                    <img
                                                                        src={window.location.protocol + "//" + window.location.host + "/icon/double-bed.png"}
                                                                        className="icon-input-update" name="linkHotelButton"
                                                                        id="linkHotelButton" onClick={this.clickLinkHotel.bind(this, 0)}
                                                                        alt="Hotel"/>
                                                                </div>
                                                                <Input type="text" name="linkHotel" id="linkHotel"
                                                                       className="form-control input-link-update-input" value={this.state.linkHotel}
                                                                       onChange={this.handleChange} aria-label="linkHotel"
                                                                       placeholder="Hotel name"
                                                                       aria-describedby="linkHotelButton"/>
                                                            </div>

                                                        </div>

                                                        <div id="carIcon0" className="col-2 col-lg-1 mb-3">
                                                            <div className="input-group-prepend">
                                                                <img
                                                                    src={window.location.protocol + "//" + window.location.host + "/icon/sedan.png"}
                                                                    className="icon-input-before" name="linkCarButton" id="linkCarButton"
                                                                    onClick={this.clickLinkCar.bind(this, 0)} alt="Car"/>
                                                            </div>
                                                        </div>
                                                        <div id="carInputBox0" className="col-6 col-lg-3 mb-3 d-none">
                                                            <div className="input-group">
                                                                <div className="input-group-prepend">
                                                                    <img
                                                                        src={window.location.protocol + "//" + window.location.host + "/icon/sedan.png"}
                                                                        className="icon-input-update" name="linkCarButton" id="linkCarButton"
                                                                        onClick={this.clickLinkCar.bind(this, 0)} alt="Car"/>
                                                                </div>
                                                                <Input type="text" name="linkCar" id="linkCar" className="form-control input-link-update-input"
                                                                       value={this.state.linkCar} onChange={this.handleChange}
                                                                       placeholder="Pick up location"
                                                                       aria-label="linkCar" aria-describedby="linkCarButton"/>
                                                            </div>

                                                        </div>

                                                        {/*<div className="col-2 col-lg-1 mb-3">*/}
                                                        {/*    <div className="input-group-prepend">*/}
                                                        {/*        <MdTrain className="icon-input" name="linkTrainButton" id="linkTrainButton" onClick={this.clickLinkTrain.bind(this, 0)}/>*/}
                                                        {/*    </div>*/}
                                                        {/*</div>*/}
                                                        {/*<div id="trainInputBox0" className="col-4 col-lg-3 mb-3 d-none">*/}
                                                        {/*    <Input type="text" name="linkTrain" id="linkTrain" className="input-link" value={this.state.linkTrain} onChange={this.handleChange} aria-label="linkTrain" aria-describedby="linkTrainButton"/>*/}
                                                        {/*</div>*/}

                                                        <div id="motorIcon0" className="col-2 col-lg-1 mb-3">
                                                            <div className="input-group-prepend">
                                                                <img
                                                                    src={window.location.protocol + "//" + window.location.host + "/icon/motorbike.png"}
                                                                    className="icon-input-before" name="linkMotorButton"
                                                                    id="linkMotorButton" onClick={this.clickLinkMotor.bind(this, 0)}
                                                                    alt="Motor"/>
                                                            </div>
                                                        </div>
                                                        <div id="motorInputBox0" className="col-6 col-lg-3 mb-3 d-none">
                                                            <div className="input-group">
                                                                <div className="input-group-prepend">
                                                                    <img
                                                                        src={window.location.protocol + "//" + window.location.host + "/icon/motorbike.png"}
                                                                        className="icon-input-update" name="linkMotorButton"
                                                                        id="linkMotorButton" onClick={this.clickLinkMotor.bind(this, 0)}
                                                                        alt="Motor"/>
                                                                </div>
                                                                <Input type="text" name="linkMotor" id="linkMotor"
                                                                       className="form-control input-link-update-input" value={this.state.linkMotor}
                                                                       onChange={this.handleChange} aria-label="linkMotor"
                                                                       placeholder="Pick up location"
                                                                       aria-describedby="linkMotorButton"/>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </Col>

                                            </Row>
                                            </div>
                                        </div>

                            </Container>
                        </div>
                    </div>
                </Container>

            </div>
        );
    }
}

export default Post